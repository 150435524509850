import styled from "styled-components";

const ContainerDiv = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1300px;
  text-align: left;
  width: calc(100% - 150px);

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    width: 100%;
  }
`;

const ContainerTextDiv = styled.div`
  text-align: center;
  width: 100%;

  p {
    max-width: 700px;
    text-align: left;
  }
`;

export {
  ContainerDiv,
  ContainerTextDiv
}