import React, { FC, useContext } from 'react';

import Contact from '../../components/Contact';
import Header from '../../layout/Header';
import Info from '../../components/Info';
import Machines from '../../components/Machines';
import News from '../../components/News';
import Video from '../../components/Video';
import Webdoor from '../../components/Webdoor';

import { MainContext } from '../../providers/MainProvider';

// home
const Home: FC<any> = () => {
  const {
    footer,
    info,
    news,
    modal,
    machines,
    video,
    webdoor
  }: any = useContext(MainContext);

  // render
  return (
    <>
      <Header />

      {webdoor &&
        <Webdoor {...webdoor} />}

      {info && 
        <Info {...info} />}

      {machines &&
        <Machines {...machines} />}

      {video &&
        <Video {...video} />}

      {news &&
        <News {...news} />}

      {footer &&
        <Contact footer={footer} modal={modal} />}
    </>
  );
};

export default Home;