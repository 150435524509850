import styled from "styled-components";

const CollapseDiv = styled.div`
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    > div {
      padding: 0;
    }
  }
`;

const CollapseContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    > div {
      padding: 0;
    }
  }
`;

export {
  CollapseDiv,
  CollapseContainer
}