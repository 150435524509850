import React, { FC, useState } from 'react';

import CollapseItemBody from './CollapseItemBody';
import CollapseItemHeader from './CollapseItemHeader';

import { ICollapseItem } from './interfaces';

import { CollapseItemDiv } from './styles';

// collapse item
const CollapseItem: FC<ICollapseItem> = ({
  children,
  title
}) => {
  const [ collapse, setCollapse ] = useState<boolean>(false);

  // render
  return (
    <CollapseItemDiv data-collapse={collapse}>
      <CollapseItemHeader
        collapse={collapse}
        setCollapse={setCollapse}
        title={title} />
      
      <CollapseItemBody collapse={collapse}>
        {children}
      </CollapseItemBody>
    </CollapseItemDiv>
  );
};

export default CollapseItem;