import styled from "styled-components";

const WebdoorContainerDiv = styled.div`
  float: right;
  padding: 115px 0 40px 30px;
  width: 50%;

  h4,
  h2,
  p {
    margin: 0;
  }

  h4 {
    color: ${({ theme }) => theme.red_900};
    margin-bottom: 30px;
  }

  h2 {
    max-width: 540px;
    color: ${({ theme }) => theme.white_900};
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  p {
    max-width: 510px;
    color: ${({ theme }) => theme.white_900};
  }

  .link {
    background-color: ${({ theme }) => theme.red_900};
    border-radius: 5px;
    cursor: pointer;
    float: left;
    margin: 30px 0 0;
    padding: 15px 25px;

    p {
      font-weight: 700;
      color: ${({ theme }) => theme.white_900};
    }
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    padding: 80px 70px 40px 30px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 80px 20px 40px;
    width: 100%;
  }
`;

export {
  WebdoorContainerDiv
}