import React, { FC } from 'react';
import { Link as LinkScroll } from "react-scroll";
import { ButtonRouteLink } from '../../components/Form/Button/styles';

import { ContainerDiv } from '../Container/styles';
import { HeaderNewsHeader } from './styles';

import { IHeaderNews } from './interfaces';

// const { PUBLIC_URL } = process.env;

// header news
const HeaderNews: FC<IHeaderNews> = ({
  type = 1
}) => {
  return (
    <HeaderNewsHeader data-type={type}>
      <ContainerDiv>
        <LinkScroll
          to="webdoor"
          data-aos="fade-down"
          className="link">
          <span className="icon icon-logo"></span>
        </LinkScroll>

        {type === 2 &&
          <ButtonRouteLink
            className="inv"
            to="/">
            Ver Geradores Cummins
          </ButtonRouteLink>}
      </ContainerDiv>
    </HeaderNewsHeader>
  );
};

export default HeaderNews;