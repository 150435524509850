import React, { createContext, FC, useState } from 'react';
import { ThemeProvider as StyleProvider } from "styled-components";

import { IThemeContext, IThemeProvider } from './interfaces';

// theme context
const ThemeContext: any = createContext({} as IThemeContext);

// theme provider
const ThemeProvider: FC<IThemeProvider> = ({ children }) => {  
  const [ theme ]: any = useState({
    black_900: '#000000',
    black_800: '#454545',
    black_700: '#373737',
    black_600: '#E9E9E9',
    black_500: '#494949',
    red_900: '#FC2918',
    red_500: '#EE6400',
    green_900: '#019A11',
    white_900: '#FFFFFF'
  });

  // render
  return (
    <StyleProvider theme={theme}>
      <ThemeContext.Provider
        value={{
          theme
        }}>
        {children}
      </ThemeContext.Provider>
    </StyleProvider>
  );
};

export { ThemeContext, ThemeProvider };
export default ThemeProvider;