import styled from "styled-components";

const H1 = styled.h1`
  font-family: Helvetica B;
  font-style: normal;
  font-size: 100px;
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 1em;
`;

const H2 = styled.h2`
  font-family: Helvetica B;
  font-style: normal;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 1em;
`;

const H3 = styled.h3`
  font-family: Helvetica B;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  letter-spacing: -0.03em;
  line-height: 1.2em;
`;

const H4 = styled.h4`
  font-family: Helvetica B;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  letter-spacing: -0.03em;
  line-height: 1em;
`;

const P = styled.p`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4em;

  ul {
     margin: 0 0 20px;
    list-style: none;
    padding: 0 0 0 30px;
    width: 100%;

    li {
      margin: 0 0 10px;
      width: 100%;

      &:before {
        background: ${({ theme }) => theme.red_900};
        border-radius: 0;
        content: '';
        height: 10px;
        left: -28px;
        position: absolute;
        top: 9px;
        width: 10px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
`;

const PDiv = styled.div`
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4em;

  ul {
    margin: 0 0 20px;
    list-style: none;
    padding: 0 0 0 30px;
    width: 100%;

    li {
      margin: 0 0 10px;
      width: 100%;

      &:before {
        background: ${({ theme }) => theme.red_900};
        border-radius: 0;
        content: '';
        height: 10px;
        left: -28px;
        position: absolute;
        top: 9px;
        width: 10px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
`;

const PMin = styled.p`
  font-family: Helvetica B;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: -0.03em;
  line-height: 1.4em;
`;

export {
  H1,
  H2,
  H3,
  H4,
  P,
  PDiv,
  PMin,
}