import React, { FC, useState, useMemo } from 'react';
import { Formik } from 'formik';
import axios from 'axios';

import InputForm from '../../Form/Input/InputForm';
import InputGroup from '../../Form/Input/InputGroup';
import Modal from '../../Modal';
import Select from '../../Form/Select';

import useForm from '../../../hooks/useForm';

import { P } from '../../Typography/styles';
import { ButtonDefault } from '../../Form/Button/styles';
import {  ContactFormDiv, ContactFormModalDiv, ContactFormFooterChecksDiv,  ContactFormFooterDiv, ContactFormForm } from './styles';

import { states } from './states';

import { IContactForm } from './interfaces';

// url
const { REACT_APP_CONTACT_FORM }: any = process.env;

// contact form
const ContactForm: FC<IContactForm> = ({
  modal
}) => {
  const estados = useMemo(() => [...states], [])
  const [ open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { typesForm } = useForm();

  // initial
  const initialValues: any = {
    nome: '',
    empresa: '',
    cargo: '',
    telefone: '',
    email: '',
    estado: '',
    cidade: '',
    mensagem: '',
    promocoes: false,
    declaracao: false
  };

  // render
  return (
    <ContactFormDiv>
      <Formik
        // init
        initialValues={initialValues}
        initialStatus={{ success: false }}
        // validate
        validate={(values: any) => {
          const errors: any = typesForm({ ...values }, 'contact');
          setError(Object.keys(errors).length > 0);

          return errors;
        }}
        // on submit
        onSubmit={(values, {
          setSubmitting,
          setStatus,
          resetForm
        }) => {
          setStatus({ success: false });

          const params = values;
          const bodyFormData = new FormData();

          for (let param in params) {
            bodyFormData.append(param, params[param]);
          }
          bodyFormData.append('pais', 'brasil');

          axios(REACT_APP_CONTACT_FORM, {
            method: 'POST',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then(async(e: any) => {
            resetForm(initialValues);
            setStatus({ success: true });
            return e;
          }).catch(e => setStatus({ error: true }));

          setSubmitting(false);
        }}>
        {({
          values,
          errors,
          status,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue
        }) => (
          <>
            <ContactFormForm
              autoComplete="off"
              noValidate
              data-submitting={isSubmitting}>
              <InputForm
                label="Nome"
                name="nome"
                type="text"
                error={errors.nome}
                empty={!values?.nome}
                required={true} />

              <InputGroup type={2}>
                <InputForm
                  label="Empresa"
                  name="empresa"
                  type="text"
                  error={errors.empresa}
                  empty={!values?.empresa}
                  required={true} />

                <InputForm
                  label="Cargo"
                  name="cargo"
                  type="text"
                  error={errors.cargo}
                  empty={!values?.cargo}
                  required={true} />
              </InputGroup>

              <InputGroup type={2}>
                <InputForm
                  label="Telefone"
                  name="telefone"
                  type="phone"
                  value={values?.telefone}
                  error={errors.telefone}
                  empty={!values?.telefone}
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur} />

                <InputForm
                  label="E-mail"
                  name="email"
                  type="email"
                  error={errors.email}
                  empty={!values?.email}
                  required={true} />
              </InputGroup>

              <InputGroup type={2}>
                <InputGroup>
                  <Select
                    defaultText="Estado"
                    options={estados}
                    handleBlur={handleBlur}
                    handleChange={setFieldValue}
                    selectedOption={values?.estado}
                    error={errors.estado}
                    name="estado"
                    value={values?.estado} />
                </InputGroup>

                <InputGroup>
                  <InputForm
                    label="Cidade"
                    name="cidade"
                    type="text"
                    error={errors.cidade}
                    empty={!values?.cidade}
                    required={true} />
                </InputGroup>
              </InputGroup>

              <InputGroup>
                <InputForm
                  empty={values?.mensagem}
                  label="Sua Mensagem"
                  name="mensagem"
                  type="textarea"
                  error={errors.mensagem}
                  required={true} />
              </InputGroup>

              <ContactFormFooterChecksDiv>
                <InputGroup type={3}>
                  <InputForm type="check" name="declaracao" />
                  <P>
                    Os dados informados serão utilizados para que possamos retornar o seu contato. Por favor, nos dê seu aceite após leitura da nossa
                    <button
                      type="button"
                      onClick={() => setOpen(true)}>Declaração de Proteção de Dados.</button>
                  </P>
                </InputGroup>
                <InputGroup type={3}>
                  <InputForm type="check" name="promocoes" />
                  <P>A Cummins faz uso do endereço de e-mail do titular dos dados pessoais para fins promocionais e de marketing através do envio de informações via e-mail. Para ACEITE de recebimento dos e-mails promocionais e de marketing da Cummins, por favor, concorde com este aviso.</P>
                </InputGroup>
              </ContactFormFooterChecksDiv>

              <ContactFormFooterDiv>
                <div className="info">
                  {status.success === true &&
                    <h1 className="status success">Mensagem enviada com sucesso!</h1>}

                  {(status.error === true || Object.keys(error).length > 0) &&
                    <h1 className="status error">Ops, algo está errado!</h1>}
                </div>

                <ButtonDefault
                  className="form-contact--button"
                  type="submit"
                  data-disabled={isSubmitting || Object.keys(errors).length > 0 || !values.declaracao || !values.promocoes}>Enviar</ButtonDefault>
              </ContactFormFooterDiv>
            </ContactFormForm>
          </>
        )}
      </Formik>
      <Modal open={open} setOpen={setOpen} type="">
        <ContactFormModalDiv>
          <P className="title">{modal?.title}</P>
          <P className="description">{modal?.description}</P>
          <P className="subtitle">{modal?.subtitle}</P>
          <ul className="list">
            {modal?.list && modal.list.map((text: string, index: number) => 
              <li className="list--item" key={index}>
                <P className="text">{text}</P>
              </li>)}
          </ul>
          <P className="footer">{modal?.footer}</P>
        </ContactFormModalDiv>
      </Modal>
    </ContactFormDiv>

  );
};

export default ContactForm;