import styled from "styled-components";

const NewsContentDiv = styled.div`
  min-height: 100vh;
  width: 100%;

  .inv {
    right: 0;
    position: absolute;
    top: 50px;
    z-index: 5;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    .inv {
      right: 10px;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .inv {
      margin: 40px 20px 10px;
      right: initial;
      position: relative;
      top: initial;
      text-align: center;
      width: calc(100% - 40px);
    }
  }
`;

const NewsContentGridDiv = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr;
  gap: 0;
  margin: 0 0 180px;
  padding: 0;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    grid-template-columns: 1fr;
  }
`;

export {
  NewsContentDiv,
  NewsContentGridDiv
}