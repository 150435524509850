import styled from "styled-components";

const WebdoorNewsDiv = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px;
  margin: 0 0 20px;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: -90px 0 0;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    grid-template-columns: 1fr;
  }
`;

const WebdoorNewsImageDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  float: left;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const WebdoorNewsInfoDiv = styled.div`
  background-color: ${({ theme }) => theme.black_900};
  float: left;
  padding: 180px 150px 75px;
  width: 100%;

  .container {
    float: right;
    padding: 0;
    max-width: 490px;

    .date,
    .link,
    .title {
      color: ${({ theme }) => theme.white_900};
      float: left;
      width: 100%;
    }

    .date {
      color: ${({ theme }) => theme.red_900};
      font-weight: 700;
    }

    .link {
      width: auto;
    }

    .title {
      margin-top: 0px;
      text-transform: uppercase;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 120px 20px 60px;
  }
`;

export {
  WebdoorNewsDiv,
  WebdoorNewsInfoDiv,
  WebdoorNewsImageDiv
}