import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { P } from '../../components/Typography/styles';
import { ContainerDiv } from '../Container/styles';

import { FooterSection, FooterLogoDiv, FooterNetworksDiv } from './styles';

// footer
const Footer: FC<any> = ({
  networks,
  text_copy
}) => {
  // return
  return (
    <FooterSection>
      <ContainerDiv>
        <FooterLogoDiv>
          <span className="icon icon-logo"></span>
          <P className="text">{text_copy}</P>
        </FooterLogoDiv>

        <FooterNetworksDiv>
          <Link className="link" to="/privacidade">Privacidade e assuntos jurídicos</Link>

          <P className="text">{networks?.title}</P>

          <ul className="list">
            {networks.list && networks.list.map(({ href, icon }: any, index: number) =>
              <li className="list--item" key={index}>
                <a className="link" href={href} target="_blank" rel="noreferrer">
                  <span className={`icon ${icon}`}></span>
                </a>
              </li>)}
          </ul>
        </FooterNetworksDiv>
      </ContainerDiv>
    </FooterSection>
  );
};

export default Footer;