import React, { FC, useEffect, useCallback } from 'react';

import NewsItem from '../../../components/News/NewsItem';
import Subtitle from '../../../components/Typography/Subtitle';

import { ButtonRouteLink } from '../../../components/Form/Button/styles';
import { ContainerDiv } from '../../../layout/Container/styles';
import { NewsContentDiv, NewsContentGridDiv } from './styles';

// news content
const NewsContent: FC<any> = ({
  data,
  loadNext,
  page,
  total,
}) => {
  // get doc height
  const getDocHeight = useCallback(() => {
    const D = document;

    return Math.max(
      D.body.scrollHeight, D.documentElement.scrollHeight,
      D.body.offsetHeight, D.documentElement.offsetHeight,
      D.body.clientHeight, D.documentElement.clientHeight
    );
  }, []);

  // use effect
  useEffect(() => {
    const handleScroll = () => {
      if ((Math.abs(window.pageYOffset) + window.innerHeight) >= getDocHeight()) {
        if (total <= page) {
          loadNext(page + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true }); 

    return () => window.removeEventListener("scroll", handleScroll);
  }, [getDocHeight, loadNext, page, data, total]);

  // render
  return (
    <NewsContentDiv>
      <ContainerDiv>
        <ButtonRouteLink
          className="inv"
          to="/">
          Ver Geradores Cummins
        </ButtonRouteLink>

        <Subtitle subtitle="notícias" />

        <NewsContentGridDiv>
          {data?.items && data?.items.map((item: any, index: number) =>
            <NewsItem
              {...item}
              key={index} />)}
        </NewsContentGridDiv>
      </ContainerDiv>
    </NewsContentDiv>
  );
};

export default NewsContent;