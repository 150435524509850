import styled from "styled-components";
import { rgba } from 'polished';

const VideoSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  float: left;
  width: 100%;
  z-index: 2;

  > div {
    display: inline-block;
  }
`;

const VideoBackgroundDiv = styled.div`
  left: 0;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  img {
    height: 100%;
    float: left;
    object-fit: cover;
    width: 100%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: 370px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    height: 300px;
  }
  
  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    height: 140px;
  }
`;

const VideoContentDiv = styled.div`
  float: left;
  text-align: center;
  width: 100%;

  > div {
    &:first-child {
      bottom: calc(50%);
      top: initial;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    > div {
      &:first-child {
        display: none;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0 30px;
  }
`;

const VideoContentPlayerDiv = styled.div`
  filter: drop-shadow(0px 10px 10px ${({ theme }) => rgba(theme.black_900, 0.4)});
  width: 100%;

  > div {
    min-height 470px;
    min-width: 835px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;

    > div {
      min-height auto;
      min-width: auto;
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }
`;

export {
  VideoSection,
  VideoBackgroundDiv,
  VideoContentDiv,
  VideoContentPlayerDiv,
}