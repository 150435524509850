import styled from "styled-components";

const NoticeSection = styled.section`
  margin: 0;
  min-height: 100vh;
  width: 100%;
`;

const NoticeSectionDiv = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const NoticeContainerDiv = styled.div`
  align-items: center;
  align-content: center;
  display: inline-flex;
  justify-content: center;
  flex-flow: column wrap;
  margin: 0;
  padding: 200px 0 0;
  width: 100%;

  .date,
  .image,
  .text {
    margin: 0;
  }

  .date {
    color: ${({ theme }) => theme.red_900};
    font-weight: 600;
    letter-spacing: -0.03em;
  }

  .image {
    margin: 10px 0 20px;
  }

  .iframe-container {
    height: 0;
    overflow: hidden; 
    padding-top: 30px;
    padding-bottom: 56.25%;
    width: 100%;

    iframe, object, embed {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .text {
    color: ${({ theme }) => theme.black_800};
    max-width: 700px;
    letter-spacing: -0.03em;
  }

  > div {
    padding: 20px 0;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0 30px;

    .image {
      width: 100%;
    }
  }
`;

const NoticeFooterDiv = styled.div`
  margin: 0 0 190px;
  padding: 60px 0 20px;
  text-align: center;
  width: 100%;
`;

export {
  NoticeSection,
  NoticeContainerDiv,
  NoticeSectionDiv,
  NoticeFooterDiv
}