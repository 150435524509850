import React, { FC } from 'react';

import { ICollapseItemBody } from './interfaces';

import { CollapseItemBodyDiv } from './styles';

// collapse item body
const CollapseItemBody: FC<ICollapseItemBody> = ({
  children,
  collapse
}) => {
  return (
    <CollapseItemBodyDiv data-collapse={collapse}>
      {children}
    </CollapseItemBodyDiv>
  );
};

export default CollapseItemBody;