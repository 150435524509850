import styled from "styled-components";
import { rgba } from 'polished';

const TitleDiv = styled.div`
  margin: 0;
  left: 0;
  position: absolute;
  transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: left center;
  top: 50%;
  width: auto;

  h1 {
    color: transparent;
    margin: 0;
    line-height: 1em;
    text-transform: uppercase;
    -webkit-text-stroke: 2px ${({ theme }) => rgba(theme.black_900, 0.4)};

    &:before {
      background-color: ${({ theme }) => theme.red_900};
      content: '';
      height: 50px;
      left: 100%;
      position: absolute;
      width: 50px;
      z-index: -1;
    }
  }

  &[data-type="1"] {
    h1 {
      &:before {
        left: calc(100% - 35px);
        top: 50px;
      }
    }
  }

  &[data-type="2"] {
    left: initial;
    right: -25px;
    transform: rotate(-90deg) translate(50%, -50%);
    transform-origin: right center;
    top: calc(50% + 60px);

    h1 {
      &:before {
        left: calc(100% - 35px);
        top: -25px;
      }
    }
  }
`;

export {
  TitleDiv
}