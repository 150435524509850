import axios from 'axios';

// data
const fetchPage = async (url: string, id: number) => {
  const data = await axios.get(`${url}/api/v2/pages/${id}`)
    .then(({ data, status }) => {
      return {
        page: data,
        status: status,
        isLoading: !status,
      };
  });
  
  return data;
};

// use api
async function UseNews(base: string, slug?: string) {
  const news = await axios.get(`${base}/api/v2/pages/?slug=${slug}&type=imprensa.Noticia`)
    .then(({ data }: any) => data.items[0]);

  return fetchPage(base, news.id);
};

export default UseNews;