import styled from "styled-components";

const InputGroupDiv = styled.div`
  float: left;
  width: 100%;

  &[data-type="2"] {
    > div,
    > label {
      width: 50%;
    }

    > div,
    > label {
      &:first-child {
        margin-right: 10px;
        width: calc(50% - 10px);
      }
      
      &:last-child {
        margin-left: 10px;
        width: calc(50% - 10px);
      }
    }
  }

  &[data-type="3"] {
    display: inline-flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 5px 0 10px;

    label {
      margin: 0;
      width: 20px;
    }

    p {
      color: ${({ theme }) => theme.black_800};
      font-size: 13px;
      margin: 0;
      letter-spacing: -0.03em;
      padding: 0 0 0 10px;
      text-align: left;
      width: calc(100% - 20px);
    }

    /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    button {
      width: auto;
    }
  }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    &[data-type="2"] {
      > div,
      > label {
        width: 100%;
      }

      > div,
      > label {
        &:first-child {
          margin-right: 0;
          width: 100%;
        }
        
        &:last-child {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
`;

export {
  InputGroupDiv
}