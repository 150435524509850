import React, { FC } from 'react';

import { ContainerDiv } from '../../layout/Container/styles';
import { PMin } from '../Typography/styles';
import {
  MachinesSection,
  MachinesImagesDiv,
  MachinesListUl,
  MachinesContainerDiv
} from './styles';

import { IMachines } from './interfaces';

const { PUBLIC_URL } = process.env;

// machines
const Machines: FC<IMachines> = ({
  list,
  images,
  title
}) => {
  return (
    <MachinesSection>
      <ContainerDiv>
        <MachinesImagesDiv data-aos="fade-up">
          {images && images.map(({ url }: any, index: number) =>
            <img src={`${PUBLIC_URL}${url}`} alt={title} key={index} />)}
        </MachinesImagesDiv>

        <MachinesContainerDiv data-aos="fade-down">
          <PMin>{title}</PMin>

          <MachinesListUl>
            {list && list.map((item: string, index: number) =>
              <li key={index}>
                <PMin><span className="number">{index + 1}.</span> {item}</PMin>
              </li>)}
          </MachinesListUl>
        </MachinesContainerDiv>
      </ContainerDiv>
    </MachinesSection>
  );
};

export default Machines;