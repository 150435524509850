import styled from "styled-components";

const NewsPageSection = styled.section`
  width: 100%;
`;

const NewsPageLoaderDiv = styled.div`
  align-items: center;
  background: transparent;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;

  img {
    animation: spin 4s linear infinite;
    width: 35px;
  }

  &[data-loader="false"] {
    display: none;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% { 
      transform: rotate(360deg);
    }
  }
`;

export {
  NewsPageSection,
  NewsPageLoaderDiv
}