import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Main from './layout/Main';

import reportWebVitals from './reportWebVitals';

import { MainProvider } from './providers/MainProvider';
import { ThemeProvider } from './providers/ThemeProvider';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter
      basename="/">
      <MainProvider>
        <ThemeProvider>
          <Main />
        </ThemeProvider>
      </MainProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
