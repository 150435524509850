import React, { FC } from 'react';

import { ISelectItem } from './interfaces';

import { SelectItemButton } from './styles';

// select item
const SelectItem: FC<ISelectItem> = ({
  item,
  onSelectOption,
  selectedOption,
  translate
}) => {
  // render
  return (
    <SelectItemButton
      type="button"
      onClick={() => onSelectOption(item)}
      data-active={selectedOption === item?.value}>
      {item && <p className="text">
        {translate === false ? item?.label : item?.label}</p>}
    </SelectItemButton>
  );
};

export default SelectItem;