import styled from "styled-components";

const CollapseItemDiv = styled.div`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.black_600};
  width: 100%;
`;

export {
  CollapseItemDiv
}