import React, { Fragment, FC } from 'react';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';

import { IInputForm } from './interfaces';

import { InputLabel } from './styles';

// Input
const InputForm: FC<IInputForm> = ({
  maxLength,
  required,
  empty,
  name,
  label,
  info,
  type,
  error,
  value = '',
  checked,
  setFieldValue,
  onChange
}) => {
  // get input type
  const getInputType = () => {
    switch (type) {
      case 'phone':
        return <NumberFormat 
          name={name}
          onChange={onChange}
          format="(##) ####-#####"
          mask=""
          value={value}
          required />;

      case 'check':
        return <>
          <Field
            name={name}
            type="checkbox" 
            required={required} />
          <label className="icon icon-check"></label>
        </>;
          
      case 'email':
        return <Field
          name={name}
          type="email" 
          required={required} />;

      case 'radio':
        return <>
          <input
            type="radio"
            id={name+value}
            name={name}
            checked={checked}
            onChange={() => setFieldValue(name, value)} 
            required={required} />
          <label className="radio-icon" htmlFor={name+value}></label>
        </>;

      case 'textarea':
        return <>
          <Field
            rows="10"
            name={name}
            component="textarea"
            required={required} />
          <label className="info">{info}</label>
        </>;

      case 'text':
      default:
        return <Field
          type="text"
          name={name}
          maxLength={maxLength}
          required={required} />;
    }
  };

  // render
  return (
    <InputLabel
      data-empty={!empty}
      data-error={error}
      data-type={type}>
      {getInputType()}
      <label className="label">{label}</label>
    </InputLabel>
  );
};

export default InputForm;