import { rgba } from "polished";
import styled from "styled-components";

const ModalDiv = styled.div`
  background-color: transparent;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  &:before {
    background-color: ${({ theme }) => rgba(theme.black_900, 0.6)};
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const ModalContainerDiv = styled.div`
  height: auto;
  max-height: calc(100% - 40px);
  max-width: 800px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 60px);
`;

const ModalHeaderDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  border-radius: 10px 10px 0 0;
  padding: 0;
  width: 100%;

  button {
    background-color: ${({ theme }) => theme.red_900};
    border: none;
    border-radius: 0px 10px;
    box-shadow: none;
    float: right;
    height: 47px;
    outline: none;
    top: 0px;
    width: 47px;

    .icon {
      color: ${({ theme }) => theme.white_900};
      font-size: 16px;
    }
  }
`;

const ModalBodyDiv = styled.div`
  background-color: ${({ theme }) => theme.white_900};
  border-radius: 0 0 10px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export {
  ModalDiv,
  ModalContainerDiv,
  ModalHeaderDiv,
  ModalBodyDiv
}