import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import Footer from '../../layout/Footer';
import NewsContent from './NewsContent';
import WebdoorNews from '../../components/WebdoorNews';

import { MainContext } from '../../providers/MainProvider';

import { NewsPageLoaderDiv, NewsPageSection } from './styles';
import HeaderNews from '../../layout/HeaderNews';

const { REACT_APP_BASE_API, REACT_APP_PAGINATION_NEWS, PUBLIC_URL }: any = process.env;
const pagination = parseInt(REACT_APP_PAGINATION_NEWS);

// news
const News: FC<any> = () => {
  const { footer }: any = useContext(MainContext);

  const [page, setPage] = useState<number>(1);

  const [data, setData] = useState<any>({ items: [] });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // query
  const query: string = '?type=imprensa.Noticia&fields=image,intro,date&order=-first_published_at';

  // get total pages
  const getTotal = useCallback(async (total_count: number = 4) => {
    const total = await Math.ceil(total_count / page) || 1;

    return total;
  }, [ page ]);

  // on load next
  const loadNext = useCallback(async (page: number) => {
    const offset = (page - 1) * pagination;

    try {
      const result = await axios.get(
        `${REACT_APP_BASE_API}/api/v2/pages/${query}&offset=${offset}&limit=${pagination + 1}`
      ).then(({ data }: any) => data);

      if (!result?.items.length) return false;
  
      setData({ ...result, items: [...data?.items, ...result?.items] });
      setIsLoading(false);
      setPage(page);
    } catch(e) {
      setIsLoading(true);
    }
  }, [data]);

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await axios.get(
          `${REACT_APP_BASE_API}/api/v2/pages/${query}&offset=1&limit=${pagination + 1}`
        ).then(({ data }: any) => data);

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(true);
      }
    };

    fetchData();
  }, []);

  // render
  return (
    <NewsPageSection>
      <HeaderNews type={1} />

      <WebdoorNews />

      <NewsContent
        data={data}
        page={page}
        total={getTotal(data?.meta?.total_count)}
        loadNext={loadNext} />

      {footer &&
        <Footer {...footer} />}

      <NewsPageLoaderDiv
        data-loader={isLoading}>
        <img src={`${PUBLIC_URL}/images/loader.svg`} alt="loader" />
      </NewsPageLoaderDiv>
    </NewsPageSection>
  );
};

export default News;