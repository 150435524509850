import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import Subtitle from '../../components/Typography/Subtitle';
import HeaderNews from '../../layout/HeaderNews';
import Footer from '../../layout/Footer';

import UseNews from '../../hooks/useNews';
import useForm from '../../hooks/useForm';

import { MainContext } from '../../providers/MainProvider';

import { P, PDiv } from '../../components/Typography/styles';
import { ContainerDiv } from '../../layout/Container/styles';
import { ButtonRouteLink } from '../../components/Form/Button/styles';
import { NoticeContainerDiv, NoticeFooterDiv, NoticeSection, NoticeSectionDiv } from './styles';

import { INotice } from './interfaces';

const { REACT_APP_BASE_API }: any = process.env;

// news
const Notice: FC<INotice> = ({ location }) => {
  const ref: any = useRef<any>(null);
  const iframeRef: any = useRef<any>(null);

  const [ data, setData ]: any = useState<any>(null);
  const { footer }: any = useContext(MainContext);
  
  const { getDate } = useForm();

  // a href
  const aHref = useCallback(() => {
    const body = ref.current.querySelectorAll('.text a');

    Array.from(body).forEach((item: any) => {
      if (item instanceof Object) {
        item?.setAttribute('target', 'blank');
      }
    });
  }, [ ref ]);

  // escape html
  const escapeHtml = useCallback((unsafe) => {
    return unsafe.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
  }, []);

  // check out
  const targetBlank = useCallback((link: any) => {
    if (!link) return false;

    const url = link.getAttribute('href') || '';
    const check = url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g);
    
    if (check[0]) {
      link.setAttribute('target', 'blank');
    }
  }, []);
  
  // gambi
  const gambi = useCallback(async (data) => {
    if (!data) return false;

    const restore = await document.createElement('div');
    restore.innerHTML = data;

    restore.querySelectorAll('*').forEach((item: any) => {
      if (item instanceof Object) {
        const check = item.innerHTML.match(/iframe(.+)/g);

        if (check) {
          const iframeString = escapeHtml(item.innerHTML);
          item.insertAdjacentHTML('beforebegin', '<div class="iframe-container"></div>');

          const iframeContainer = item.previousSibling;
          iframeContainer.innerHTML =  iframeString;
          restore.removeChild(item);
        }
      }
    });

    return restore.innerHTML;
  }, [ escapeHtml ]);

  // link factory
  const linkFactory = useCallback((id: string) => {
    return axios({
      method: 'GET',
      url: `${REACT_APP_BASE_API}/api/v2/pages/?id=${id}`
    }).then(({ data }) => {
      if (data.hasOwnProperty('items') && ref.current) {
        const { items } = data;
        const link = ref.current.querySelector(`a[id="${id}"]`);

        if (link && items[0]) {
          if (items[0].meta.type === 'imprensa.Noticia') {
            link.setAttribute('href', `/noticias/${items[0].meta.slug}`);
          }
        }

        return true;
      }
    });
  }, [ ref ]);

  // links parce docs
  const linksParser = useCallback((content: any) => {
    if (!content) return '';

    const links = content.getElementsByTagName('a');

    Array.from(links).forEach((item: any) => {
      const type = item.getAttribute('linktype');
      const id: any = item.getAttribute('id');

      if (type === 'page') {
        linkFactory(id);
      } else {
        targetBlank(item);
      }
    });

    return content.innerHTML;
  }, [ linkFactory, targetBlank ]);

  // iframe
  const getIframe = useCallback(async (data: any) => {
    if (!data) return false;

    if (iframeRef instanceof Object) {
      iframeRef.current.innerHTML = await gambi(data.body);
      linksParser(iframeRef.current);
    }
  }, [ iframeRef, gambi, linksParser ]);

  // use effect
  useEffect(() => {
    const params = location?.pathname.split(`/`);
    const slug = params.filter((value: any) => Object.keys(value).length !== 0);

    // page
    const page = async () => await UseNews(REACT_APP_BASE_API, slug[slug.length - 1])
      .then(async (data) => {
        setData(data);
        aHref();

        getIframe(data.page);
      });

    page();
  }, [ aHref, location, getIframe ]);

  // render
  return (
    <NoticeSection ref={ref}>
      <HeaderNews type={2} />

      <NoticeSectionDiv>
        <ContainerDiv>
          {data?.page && <>
            <NoticeContainerDiv>
              <P className="date">{getDate(data?.page.date)}</P>
              <Subtitle subtitle={data?.page.title} />

              <img
                className="image"
                src={`${REACT_APP_BASE_API}${data?.page?.image.meta.download_url}`}
                alt={data?.page.title} />

              <PDiv className="text">
                <div ref={iframeRef}></div>
              </PDiv>
            </NoticeContainerDiv>
            <NoticeFooterDiv>
              <ButtonRouteLink
                to="/noticias">
                Ver mais notícias
              </ButtonRouteLink>
            </NoticeFooterDiv>
          </>}
        </ContainerDiv>
      </NoticeSectionDiv>

      {footer &&
        <Footer {...footer} />}
    </NoticeSection>
  );
};

export default Notice;