import styled from "styled-components";

const SelectDiv = styled.div`
  float: left;
  margin: 10px 0;
  pointer-events: auto;
  position: relative;
  vertical-align: top;
  width: 100%;
  z-index: 1;

  .select {
    &--list {
      background-color: ${({ theme }) => theme.white_900};
      border: 1px solid ${({ theme }) => theme.white_700};
      border-radius: 0 0 5px 5px;
      display: none;
      left: 0;
      max-height: 300px;
      margin: 0;
      overflow: hidden;
      overflow-y: auto;
      list-style: none;
      padding: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      vertical-align: top;
      z-index: 1;

      &--item {
        float: left;
        position: relative;
        width: 100%;
        vertical-align: top;

        &:last-child {
          > button {
            border: none;
          }
        }
      }

      &:before {
        background-color: ${({ theme }) => theme.white_900};
        border-radius: 0;
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
      }
    }
  }

  &[data-active="true"] {
    z-index: 99999;

    .select {
      &--list {
        display: inline-block;
      }
    }

    > button {
      border-radius: 5px 5px 0 0;
      border-bottom-color: transparent;
    }
  }

  &[data-error="true"] {
    > button {
      border: 1px solid ${({ theme }) => theme.red_900};
      box-shadow: 0px 0px 10px rgb(${({ theme }) => theme.red_900}, 75%);
    }
  }
`;

export {
  SelectDiv,
}