import styled from "styled-components";

const MachinesSection = styled.section`
  float: left;
  margin: 20px 0 0;
  padding: 0;
  width: 100%;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 0;
  }
`;

const MachinesImagesDiv = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 15px;
  width: 100%;

  img {
    margin: 30px 0 0;

    &:last-child {
      margin-top: 80px;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    align-content: center;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;
    margin: 0 0 40px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    img {
      max-width: 100%;
    }
  }
`;

const MachinesListUl = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  width: 350px;

  > li {
    color: ${({ theme }) => theme.black_800};
    width: 100%;

    .number {
      color: ${({ theme }) => theme.red_900};
      font-family: Helvetica BK;
      font-weight: 800;
    }

    p {
      margin: 0;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    width: 100%;

    > li {
      p {
        font-size: 19px;
      }
    }
  }
`;

const MachinesContainerDiv = styled.div`
  align-content: center;
  align-items: center;
  display: inline-flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 0 20px;
  width: 100%;

  > p {
    font-family: Helvetica B;
    margin: 20px 0 0;
    width: 350px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 0 20px;
  }
`;

export {
  MachinesSection,
  MachinesImagesDiv,
  MachinesListUl,
  MachinesContainerDiv
}