import React, { FC } from 'react';

import WebdoorContainer from './WebdoorContainer';

import { ContainerDiv } from '../../layout/Container/styles';
import { WebdoorSection, WebdoorImageDiv, WebdoorBgDiv } from './styles';

import { IWebdoor } from './interfaces';

const { PUBLIC_URL } = process.env;

// webdoor
const Webdoor: FC<IWebdoor> = ({
  background,
  description,
  image,
  link,
  subtitle,
  title
}) => {
  return (
    <WebdoorSection>
      <WebdoorBgDiv data-aos="fade-left">
        <img
          src={`${PUBLIC_URL}${background}`}
          alt={title} />
      </WebdoorBgDiv>

      <ContainerDiv
        className="container">
        <WebdoorImageDiv>
          <img
            src={`${PUBLIC_URL}${image}`}
            alt={title} />
        </WebdoorImageDiv>

        <WebdoorContainer
          description={description}
          link={link}
          subtitle={subtitle}
          title={title} />
      </ContainerDiv>
    </WebdoorSection>
  );
};

export default Webdoor;