import styled from "styled-components";

const HeaderNewsHeader = styled.header`
  left: 0;
  padding: 0 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;

  .link {
    background-color: ${({ theme }) => theme.red_900};
    float: left;
    height: 135px;
    width: 105px;

    .icon {
      bottom: 20px;
      color: ${({ theme }) => theme.white_900};
      font-size: 70px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
    }
  }

  .se-liga {
    margin: 27px 40px 0;
  }

  &[data-type="1"] {
    img {
      filter: invert(1);
    }
  }

  &[data-type="2"] {
    a {
      &:last-child {
        float: right;
        margin: 50px 0 0;
        text-align: center;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0 50px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    left: initial;
    padding: 0 20px;
    position: relative;
    top: initial;

    .link {
      height: 75px;
      width: 60px;

      .icon {
        bottom: 10px;
        font-size: 38px;
      }
    }

    .se-liga {
      margin: 10px 40px 0;
      width: 120px;
    }

    &[data-type="2"] {
      a {
        &:last-child {
          margin-bottom: 50px;
          width: 100%;
        }
      }
    }
  }
`;

export {
  HeaderNewsHeader
}