import styled from "styled-components";
import { Link } from 'react-router-dom';

const ButtonDefault = styled.button`
  background-color: ${({ theme }) => theme.red_900};
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: ${({ theme }) => theme.white_900};
  font-family: Helvetica B;
  font-size: 20px;
  line-height: 1em;
  outline: none;
  padding: 20px 40px 15px;

  &[data-disabled="true"] {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const ButtonDefaultLink = styled.a`
  background-color: ${({ theme }) => theme.red_900};
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: ${({ theme }) => theme.white_900};
  font-family: Helvetica B;
  font-size: 20px;
  line-height: 1em;
  outline: none;
  padding: 20px 40px 15px;
  text-decoration: none;

  &.min {
    font-size: 16px;
    font-weight: 600;
    padding: 13px 20px 9px;
    text-transform: capitalize;
  }
`;

const ButtonRouteLink = styled(Link)`
  background-color: ${({ theme }) => theme.red_900};
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: ${({ theme }) => theme.white_900};
  font-family: Helvetica B;
  font-size: 20px;
  line-height: 1em;
  outline: none;
  padding: 18px 40px 14px;
  text-decoration: none;

  &.min {
    font-size: 16px;
    font-weight: 600;
    padding: 13px 20px 9px;
  }

  &.inv {
    background-color: ${({ theme }) => theme.white_900};
    border: 1px solid ${({ theme }) => theme.red_900};
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    color: ${({ theme }) => theme.red_900};
    padding: 18px 20px 14px;
    text-transform: capitalize;
  }
`;

export {
  ButtonDefault,
  ButtonDefaultLink,
  ButtonRouteLink
}