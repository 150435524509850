import styled from "styled-components";

const NewsSection = styled.section`
  margin: 0 0 -100px;
  padding: 50px 0 0;
  width: 100%;
  z-index: 6;

  .slick {
    &-list,
    &-track { 
      touch-action: pan-y;
    }

    &-list {
      float: left;
      padding: 0;
      overflow: unset;
      position: relative;
      width: 100%;
    }

    &-slide {
      display: flex;

      > div {
        display: flex;
        float: left;
        padding: 0;
        position: relative;
        width: 100%;
      }
    }

    &-slider {
      float: left;
      padding: 0 120px 0;
      position: relative;
      width: 100%;

      /* Small Devices, Tablets */
      @media only screen and (max-width : 768px) {
        padding: 0 20px;
      }
    }

    &-track {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0;
      margin: 0 auto;
      position: relative;
    }
  }

  > div {
    > div {
      &:first-child {
        top: calc(50% + 300px);
      }

      &:nth-child(2) {
        padding-bottom: 50px;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    .slick-list {
      overflow: hidden;
    }

    > div {
      > div {
        &:first-child {
          display: none;
        }
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .slick {
      &-track {
        display: inline-flex;
        flex-flow: row wrap;
      }
    }
  }
`;

export {
  NewsSection
}