import React, { FC } from 'react';

import Subtitle from '../Typography/Subtitle';

import { ContainerDiv, ContainerTextDiv } from '../../layout/Container/styles';
import { ContactSection } from './styles';
import ContactForm from './ContactForm';
import Title from '../Typography/Title';
import { P } from '../Typography/styles';
import Footer from '../../layout/Footer';

// contact
const Contact: FC<any> = ({
  modal,
  footer
}) => {
  // render
  return (
    <ContactSection id="contato">
      <ContainerDiv>
        <Subtitle
          subtitle="fale conosco" />
          
        <Title
          title="fale conosco" />

        <ContainerTextDiv
          className="container">
          <P>{footer?.title}</P>
        </ContainerTextDiv>

        <ContactForm modal={modal} />
      </ContainerDiv>

      <Footer {...footer} />
    </ContactSection>
  );
};

export default Contact;