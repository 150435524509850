import React, { useEffect, FC } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AOS from 'aos';
import { animateScroll } from "react-scroll";

import Home from '../../pages/Home';
import News from '../../pages/News';
import Notice from '../../pages/Notice';

import { MainSection } from './styles';
import 'aos/dist/aos.css';
import PrivacyLegal from '../../pages/PrivacyLegal';

// Main
const Main: FC<any> = ({ location }) => {
  // use effect
  useEffect(() => {
    animateScroll.scrollTo(0);
    AOS.init({ duration : 1000 });
  }, [ location ]);

  // render
  return (
    <MainSection>
      <Switch>
        <Route
          path={'/'}
          exact>
          <Home />
        </Route>

        <Route
          path={'/noticias'}
          exact>
          <News />
        </Route>

        <Route
          path={'/noticias/:slug'}
          exact>
          <Notice location={location} />
        </Route>

        <Route
          path={'/privacidade'}
          exact>
          <PrivacyLegal />
        </Route>
      </Switch>
    </MainSection>
  );
};

export default withRouter(Main);