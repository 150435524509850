import styled from "styled-components";
import { Form } from 'formik';

const ContactFormDiv = styled.div`
  background-color: transparent;
  margin: 0 0 95px;
  padding: 0;
  text-align: center;
  width: 100%;

  .contact-form {
    &--title {
      margin: 5px 0 10px;
      width: 100%;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    order: 1;
    padding: 0px 20px 50px;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

const ContactFormFooterDiv = styled.div`
  text-align: right;
  width: 100%;

  .info {
    float: left;
    padding: 30px 0;
    text-align: left;
    width: calc(100% - 200px);

    .status {
      font-size: 18px;

      &.success {
        color: ${({ theme }) => theme.green_900};
      }

      &.error {
        color: ${({ theme }) => theme.red_500};
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .info {
      padding: 20px 0 0;
      width: 100%;
    }
  }
`;

const ContactFormForm = styled(Form)`
  max-width: 700px;
  width: 100%;

  button {
    &[type="submit"] {
      cursor: pointer;
      margin-top: 20px;
    }
  }

  &[data-submitting="true"] {
    pointer-events: none;
    opacity: 0.6;
  }
`;

const ContactFormFooterChecksDiv = styled.div`
  margin: 10px 0 0;
  width: 100%;

  button {
    background: transparent;
    border: none;
    box-shadow: none;
    color: ${({ theme }) => theme.red_900};
    text-decoration: underline;
  }
`;

const ContactFormModalDiv = styled.div`
  padding: 20px 40px 40px;

  .description,
  .footer,
  .list,
  .subtitle,
  .title {
    float: left;
    margin: 0;
    width: 100%;
  }

  .footer,
  .description {
    color: ${({ theme }) => theme.black_800};
    letter-spacing: -0.03em;
    line-height: 1.3em;
    white-space: pre-line;
  }

  .footer {
    margin: 10px 0 0;
  }

  .list {
    margin: 20px 0 0;
    padding: 0;

    &--item {
      float: left;
      margin: 0 0 10px;
      padding: 0 0 0 20px;
      width: 100%;

      .text {
        color: ${({ theme }) => theme.black_800};
        font-weight: 400;
        margin: 0;
      }

      &:before {
        background-color: ${({ theme }) => theme.red_900};
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        top: 8px;
        width: 10px;
      }
    }
  }

  .subtitle {
    color: ${({ theme }) => theme.black_800};
    font-weight: 700;
    margin: 20px 0 0;
  }

  .title {
    color: ${({ theme }) => theme.black_900};
    font-weight: 600;
    margin: 0 0 15px;
    text-transform: uppercase;
  }
`;

export {
  ContactFormDiv,
  ContactFormFooterDiv,
  ContactFormModalDiv,
  ContactFormForm,
  ContactFormFooterChecksDiv
}