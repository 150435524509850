import React, { FC } from 'react';

import { ICollapseItemHeader } from './interfaces';

import { CollapseItemHeaderDiv } from './styles';

// collapse item header
const CollapseItemHeader: FC<ICollapseItemHeader> = ({
  collapse,
  setCollapse,
  title
}) => {
  // render
  return (
    <CollapseItemHeaderDiv
      onClick={() => setCollapse(!collapse)}
      data-collapse={collapse}>
      <p className="title">{title}</p>

      <button
        className="btn-collapse"
        data-active={collapse}>
        <span className="icon icon-arrow-down"></span>
      </button>
    </CollapseItemHeaderDiv>
  );
};

export default CollapseItemHeader;