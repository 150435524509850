import React from 'react';
import { Link as LinkScroll } from "react-scroll";
import { ContainerDiv } from '../Container/styles';

import { HeaderSection } from './styles';

// header
const Header = () => {
  return (
    <HeaderSection>
      <ContainerDiv>
        <LinkScroll
          to="webdoor"
          data-aos="fade-down"
          className="link">
          <span className="icon icon-logo"></span>
        </LinkScroll>
      </ContainerDiv>
    </HeaderSection>
  );
};

export default Header;