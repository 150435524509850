import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';

import useForm from '../../hooks/useForm';

import { ButtonRouteLink } from '../Form/Button/styles';
import { H2, P } from '../Typography/styles';
import { WebdoorNewsDiv, WebdoorNewsImageDiv, WebdoorNewsInfoDiv } from './styles';

// query
const query: string = '?type=imprensa.Noticia&fields=image,intro,date&order=-first_published_at';

const { REACT_APP_BASE_API } = process.env;

// webdoor news
const WebdoorNews: FC<any> = () => {
  const { getDate } = useForm();
  const [data, setData] = useState<any>(null);

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${REACT_APP_BASE_API}/api/v2/pages/${query}&offset=0&limit=1`
        ).then(({ data }: any) => data);

        setData(result);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };

    fetchData();
  }, []);

  // render
  return (
    <WebdoorNewsDiv>
      {data &&
        <>
          <WebdoorNewsInfoDiv>
            <div className="container">
              <P className="date">{getDate(data?.items[0].date)}</P>
              <H2 className="title">{data?.items[0].title}</H2>
              <ButtonRouteLink
                className="link"
                to={`/noticias/${data?.items[0].meta.slug}`}>
                Ver mais
              </ButtonRouteLink>
            </div>
          </WebdoorNewsInfoDiv>

          <WebdoorNewsImageDiv>
            <img src={`${REACT_APP_BASE_API}${data?.items[0].image.meta.download_url}`} alt={data?.items[0].title} />
          </WebdoorNewsImageDiv>
        </>}
    </WebdoorNewsDiv>
  );
};

export default WebdoorNews;