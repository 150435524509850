import React, { FC } from 'react';
import ReactPlayer from 'react-player';

import Subtitle from '../Typography/Subtitle';
import Title from '../Typography/Title';

import { ContainerDiv } from '../../layout/Container/styles';

import {
  VideoBackgroundDiv,
  VideoContentDiv,
  VideoSection,
  VideoContentPlayerDiv
} from './styles';

import { IVideo } from './interfaces';

const { PUBLIC_URL } = process.env;

// video
const Video: FC<IVideo> = ({
  background,
  subtitle,
  title,
  url
}) => {
  return (
    <VideoSection>
      <Subtitle subtitle={subtitle} />

      <ContainerDiv>
        <VideoBackgroundDiv>
          <img src={`${PUBLIC_URL}${background}`} alt={title} />
        </VideoBackgroundDiv>

        <VideoContentDiv>
          <Title title={title} />

          <VideoContentPlayerDiv>
            <ReactPlayer url={url} />
          </VideoContentPlayerDiv>
        </VideoContentDiv>
      </ContainerDiv>
    </VideoSection>
  );
};

export default Video;