import styled from 'styled-components';

const FooterSection = styled.footer`
  margin: 0 0 40px;
  width: 100%;

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0 50px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 0 20px;
  }
`;

const FooterLogoDiv = styled.div`
  align-content: flex-start;
  align-items: center;
  display: inline-flex;
  float: left;
  flex-flow: row nowrap;
  justify-content: center;

  .icon {
    color: ${({ theme }) => theme.black_900};
    font-size: 45px;
  }

  .text {
    font-size: 13px;
    margin: 0 0 0 15px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    display: inline-block;
    text-align: center;
    width: 100%;

    .icon {
      margin-bottom: 20px;
    }

    .text {
      margin: 0;
    }
  }
`;

const FooterNetworksDiv = styled.div`
  align-content: flex-start;
  align-items: center;
  display: inline-flex;
  float: right;
  height: 45px;
  flex-flow: row nowrap;
  justify-content: center;

  .list {
    margin: 0;
    list-style: none;
    padding: 0;

    &--item {
      float: left;
      margin: 0 8px;

      .icon {
        color: ${({ theme }) => theme.black_900};
        font-size: 15px;
      }
    }
  }

  .link,
  .text {
    font-size: 13px;
    line-height: 1em;
    margin: 0 5px 0 0;
  }

  > .link {
    color: ${({ theme }) => theme.black_900};
    margin-right: 40px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 768px) {
    display: inline-block;
    float: none;
    margin: 20px 0 0;
    text-align: center;
    width: 100%;

    > .link {
      margin: 0 0 20px;
      width: 100%;
    }
  }
`;

export {
  FooterSection,
  FooterLogoDiv,
  FooterNetworksDiv
}