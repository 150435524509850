import styled from "styled-components";

const CollapseItemBodyDiv = styled.div`
  float: left;
  overflow: hidden;
  max-height: 0;
  padding: 0 60px;
  transition: all 1s;
  width: 100%;

  p {
    color: ${({ theme }) => theme.black_500};
    font-family: Helvetica L;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.03em;
    margin: 20px 0 0;
    width: 100%;

    strong {
      color: ${({ theme }) => theme.black_900};
      font-family: Helvetica B;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      margin: 40px 0 20px;
      letter-spacing: -0.03em;
      line-height: 1.2em;
      padding: 0 60px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }

    u {
      font-family: Helvetica R;
      text-decoration: none;
    }
  }

  ul {
    margin: 20px 0 0;
    list-style: none;
    padding: 0 0 0 30px;
    width: 100%;

    li {
      color: ${({ theme }) => theme.black_500};
      font-family: Helvetica L;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.03em;
      margin: 0 0 20px;
      width: 100%;

      &:before {
        background: ${({ theme }) => theme.red_900};
        border-radius: 0;
        content: '';
        height: 10px;
        left: -28px;
        position: absolute;
        top: 7px;
        width: 10px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  a {
    color: ${({ theme }) => theme.red_900};
    display: inline;
  }

  &[data-collapse="true"] {
    max-height: 20000px;
    padding: 0 60px 20px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0;

    p {
      font-size: 18px;

      strong {
        font-size: 18px;
        padding: 0;
      }
    }

    ul {
      li {
        font-size: 18px;
      }
    }

    &[data-collapse="true"] {
      max-height: 20000px;
      padding: 0 0 20px;
    }
  }
`;

export {
  CollapseItemBodyDiv
}