import React, { FC } from 'react';
import { ContainerDiv } from '../../../layout/Container/styles';
import { H3 } from '../styles';

import { ISubtitle } from './interfaces';
import { SubTitleDiv, SubTitleIconDiv } from './styles';

// subtitle
const Subtitle: FC<ISubtitle> = ({
  subtitle
}) => {
  return (
    <SubTitleDiv>
      <ContainerDiv>
        <H3>{subtitle}</H3>

        <SubTitleIconDiv>
          {Array(5).fill(0).map((val, index: number) =>
            <span key={index}></span>)}
        </SubTitleIconDiv>
      </ContainerDiv>
    </SubTitleDiv>
  );
};

export default Subtitle;