import styled from "styled-components";

const PrivacyLegalSection = styled.section`
  width: 100%;
`;

const PrivacyLegalContainer = styled.div`
  min-height: 100vh;
  padding: 200px 50px 100px;
  width: 100%;

  h2 {
    font-weight: 800;
    margin: 50px 0;
    line-height: 1.2em;
    text-align: center;
    width: 100%;
    white-space: pre-line;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 200px 20px 100px;
  }
`;

export {
  PrivacyLegalSection,
  PrivacyLegalContainer
}