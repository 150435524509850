import React, { FC } from 'react';
import CollapseItem from './CollapseItem';

import { ContainerDiv } from '../../layout/Container/styles';
import { CollapseDiv, CollapseContainer } from './styles';

// collapse item
const Collapse: FC<any> = () => {
  // render
  return (
    <CollapseDiv>
      <ContainerDiv>
        <CollapseContainer>
          <CollapseItem title="Política de Privacidade do Site">
            <p>Agradecemos seu acesso a este site, que pertence e é operado pela Cummins Inc. Esta Política de Privacidade descreve como coletamos e processamos informações pessoais, e as opções que você tem em relação a como suas informações são coletadas e usadas. A Política é válida nestas situações:</p>
            <ul><li>Sites: seu uso deste e de outros sites da Cummins que publicam ou contêm um link para esta Política de Privacidade;</li>
              <li>Produtos e serviços: suas interações com a Cummins em relação a nossos produtos e/ou serviços que se referem a ou contêm um link para esta Política de Privacidade;</li>
              <li>Telematics and Connected Solutions®: seu uso de veículos ou equipamentos comerciais equipados com aparelhos com telemática ativada que transmitem dados para a Cummins para fins relacionados ao bom funcionamento e manutenção dos veículos ou equipamentos e/ou uso com nossa ferramenta on-line Connected Solutions, o que permite que os clientes se registrem para serviços de telemática; e</li>
              <li>Ferramentas digitais: seu uso da variedade de ferramentas digitais (incluindo aplicativos) oferecida pela Cummins relacionada com nossos serviços de diagnóstico e reparo para veículos e equipamentos comerciais, como Guidanz®, INSITE™, INLINE™, QuickServe® Online, entre outros.</li>
            </ul><p><strong>Informações Pessoais que Coletamos</strong><br /> Você pode visitar nosso site sem fornecer nenhuma informação pessoal. Contudo, para fornecer determinados serviços baseados na Web a você ou permitir que você acesse determinadas seções do nosso site, precisamos que você forneça algumas informações pessoais relacionadas ao tipo de interação ou serviço: por exemplo, registrar um produto, enviar uma consulta, comprar um item, se candidatar a uma vaga, participar de uma pesquisa.</p><br /><br />
            <p>As informações pessoais que coletamos podem incluir:</p>
            <ul><li>Sua identidade (nome, e-mail, número de telefone, endereço, outras informações de contato)</li>
              <li>Seu relacionamento empresarial conosco (cliente, fornecedor, candidato a vaga, etc.)</li>
              <li>Seu interesse em nossos produtos e serviços junto às suas preferências (como o nome da empresa, seu cargo, compras, interações de atendimento, interações de vendas, interesse em produtos ou serviços específicos)</li>
              <li>Sua inscrição em qualquer um dos nossos programas (como boletim, inscrição para recebimento de e-mails, participação em uma pesquisa ou outros serviços similares que oferecemos)</li>
              <li>Seu interesse e qualificações para se juntar à equipe da Cummins (candidatura de trabalho, qualificações, referências, certificações ou licenças aplicáveis, histórico empregatício e acadêmico, etc.)</li>
              <li>Suas interações on-line ou off-line conosco (como "curtidas" e feedback via sites de mídias sociais, interações com nossos call centers, entre outros)</li>
              <li>Seu uso de veículos ou equipamentos comerciais que contenham aparelhos com telemática ativada que transmitem informações para a Cummins em relação ao bom funcionamento e manutenção do veículo/equipamento no qual este está instalado (como identificadores de veículo ou equipamento, códigos de falha e informações de funcionamento smilares, datas e horas aplicáveis, dados de localização (para avaliar as condições da estrada e fatores similares impactando o desempenho), informações de frenagem e velocidade, entre outros (consulte o Aviso de proteção de dados telemáticos).</li>
              <li>Seu uso limitado de dados pessoais nas nossas ferramentas e aplicativos digitais que são oferecidos pela Cummins em relação ao nossos serviços de diagnósticos e reparos para veículos e equipamentos comerciais (como nome e informações de contato, ferramentas e/ou aplicativos que você escolhe usar on-line e/ou via aplicativos, identificadores de veículo e/ou equipamento, dados de localização (para assistência de reparos, suporte e agendamento), informações de pagamento, entre outros (consulte o Aviso de proteção de dados de ferramentas digitais).</li>
            </ul><p>Confira o Aviso de Cookies da Cummins para obter informações sobre a coleta de informações relativas à navegação e uso do site, e suas opções em relação à objeção a esses mecanismos que tornam anônimos os dados. </p>
            <p><strong>Objetivos da Coleta de Informações Pessoais</strong><br /> A Cummins coleta informações pessoais para estas finalidades: </p>
            <ul><li>Gerenciar e apoiar suas interações conosco, como responder às suas perguntas sobre nossos produtos ou serviços, processar pedidos ou devoluções, registrá-lo em programas (mediante sua solicitação), aceitar e avaliar sua candidatura a uma vaga ou atividades similares que respondam às sua solicitações ou se relacionem às suas atividades comerciais</li>
              <li>Gerenciar e melhorar a qualidade dos nossos produtos, serviços, sites, interações nas mídias sociais e nossas ofertas</li>
              <li>Gerenciar e aprimorar os serviços que fornecemos relacionados a nossas operações de telemática</li>
              <li>Gerenciar e aprimorar os serviços que fornecemos relacionados a nossas ferramentas digitais</li>
              <li>Apoiar e aperfeiçoar nossas relações com o cliente, por exemplo, ao coletar informações e interagir com você em relação a outros produtos ou serviços que possam ser do seu interesse, com base nas suas preferências</li>
              <li>Gerenciar e atender a obrigações regulamentares jurídicas, de conformidade e exportação aplicáveis.</li>
            </ul><p>You always have the right to unsubscribe in relation to any programs or services in which you have enrolled with Cummins, and can do so by contacting our Customer Service team at <a href="https://www.cummins.com/pt/customer-assistance" target="_blank" rel="noreferrer">Care.Cummins.com</a> or our Data Privacy Team at <a href="mailto:dataprivacy@cummins.com" target="_blank" rel="noreferrer">dataprivacy@cummins.com</a> ou ao seguir as informações de descadastramento informadas em um programa ou serviço específico em questão.</p>
            <p><strong>Base Jurídica para Processamento de Informações Pessoais</strong><br /> A base jurídica para processamento das informações pessoais varia de acordo com o(s) propósito(s) da coleta.Isso pode incluir: </p>
            <ul><li>Consentimento (como gerenciamento de nossos serviços, recebimento de candidaturas a empregos, responder a consultas do usuário do website, etc.)</li>
              <li>Execução de um contrato (como o gerenciamento de seu acesso à sua conta de cliente ou fornecedor, processamento e acompanhamento de solicitações de serviço e pedidos);</li>
              <li>Cumprimento de obrigações empresariais ou jurídicas, como quando o processamento é obrigatório por lei (por exemplo, retenção de faturas de compra ou serviço); ou</li>
              <li>Nossos interesses legítimos (melhoria de nossos produtos, serviços ou website; prevenir o uso indevido ou fraude; proteger nosso website ou outro software ou propriedade da Cummins; ou personalizar nossas comunicações).</li>
            </ul><p><strong>Acesso e Compartilhamento de Informações Pessoais</strong><br /> A Cummins limita o acesso às informações pessoais que coletamos a quem precisa saber devido às suas responsabilidades com a Cummins. Quando apropriado para facilitar sua interação conosco ou nossos serviços a você, a Cummins pode compartilhar suas informações pessoais com nosso grupo de empresas, joint ventures, distribuidores autorizados e outros parceiros comerciais. Podemos também armazenar seus dados em data centers ou com provedores de nuvem que estejam fora do grupo Cummins. Em raras situações, podemos também ter a obrigação de compartilhar determinadas informações com agências governamentais ou de aplicação da lei. Nessas situações, a Cummins toma medidas razoáveis para minimizar as informações fornecidas e, quando garantido e viável, notificará a pessoa com antecedência e auxiliará com os direitos da pessoa de se opor a tal acesso.</p>
            <p>A Cummins não vende nem compartilha com terceiros informações pessoais fornecidas a nós para uso próprio separado desses terceiros (com seus próprios fins comerciais). Quando as informações são compartilhadas com as empresas do grupo ou parceiros externos nos auxiliando, exigimos que eles (e quaisquer subcontratados) mantenham um nível substancialmente equivalente de proteção para esses dados.</p>

            <p><strong>Precisão das Informações Pessoais </strong><br /> A Cummins toma providências razoáveis para assegurar que suas informações pessoais arquivadas conosco sejam precisas e atualizadas. Também incentivamos as pessoas que confiaram seus dados a nós que os mantenham atualizados (informando, por exemplo, se houve mudança no seu endereço de e-mail, endereço residencial ou número de telefone) e que mantenham suas preferências de contato atualizadas (por exemplo, em relação aos nossos produtos e serviços e à frequência dos contatos) para que tenhamos as informações corretas arquivadas e para que possamos personalizar nossos serviços às suas expectativas e necessidades. Convidamos você a entrar em contato conosco por care.cummins.com para atualizar suas informações ou preferências.</p>
            <p><strong>Defesa e Cumprimento de Direitos Individuais</strong><br /> De acordo com as leis aplicáveis, a Cummins tem processos aplicados para defender os direitos de indivíduos em relação às informações coletadas sobre eles. Isso inclui, por exemplo, o direito e saber sobre os dados que mantemos sobre você ou de obter uma cópia, e os direitos limitados de alterar seus dados, solicitar o apagamento ou se opor ao processamento de seus dados. Por fim, oferecemos aos indivíduos o direito de remover o consentimento de nossos programas comerciais e de marketing, além de nossas ofertas, a qualquer momento. Para isso, ou para exercer quaisquer um desses outros direitos, você pode entrar em contato conosco enviando um e-mail para <a href="mailto:dataprivacy@cummins.com">dataprivacy@cummins.com</a>, ou enviando uma correspondência para Cummins Inc. Global Headquarters, 500 Jackson Street, Columbus, IN USA 47201. A Cummins pode solicitar verificações adicionais a você, bem como informações mais descritivas relacionadas às suas interações com a empresa, para processar e responder às suas solicitações sem fornecer informações pessoais a uma pessoa não autorizada.</p>
            <p>Consulte a seção <b>Proteções Adicionais para Residentes de Determinadas Regiões</b> da Política deste Website para ver direitos adicionais que podem ser aplicáveis com base na região ou país de um usuário do website. </p>
            <p><strong>Proteção de Suas Informações</strong><br /> A Cummins implantou práticas físicas, eletrônicas e gerenciais apropriadas para ajudar a salvaguardar e proteger as informações que coletamos on-line contra perda, uso indevido, acesso não autorizado, alteração, destruição ou roubo. Isso inclui salvaguardas apropriadas para clientes que compram por meio de nosso site, assim como aqueles que se inscrevem em nossos programas. As proteções que adotamos são proporcionais à confidencialidade das informações e são atualizadas conforme garantido para lidar com os riscos de segurança em evolução.</p>
            <p><strong>Transferência Transfronteiriça de Informações Pessoais</strong><br /> Como uma organização global, a Cummins transfere e armazena algumas das informações pessoais que coleta fora de seu país de origem. Isso pode incluir transferências a terceiros nos auxiliando em outros países, bem como o armazenamento de dados na nuvem. Se você vive fora dos Estados Unidos, esteja ciente de que as leis de privacidade de dados que se aplicam a informações pessoais são diferentes dependendo do país e nem sempre oferecem o nível consistente de proteção oferecido em seu país. No entanto, a Cummins adotou medidas destinadas a garantir que a transferência transfronteiriça de dados pessoais ocorra em conformidade com as leis e requisitos de proteção de dados aplicáveis. As medidas incluem avaliações do importador de dados, bem como as leis aplicáveis do país de destino para garantir que o beneficiário seja capaz de manter um nível adequado de proteção para os dados e de entrar em acordos por escrito apropriados com o importador de dados, confirmando esse compromisso. A Cummins se baseia nas Cláusulas Contratuais Padrão da UE ou em um mecanismo de transferência de dados equivalente para essas transferências e também aplicou mecanismos semelhantes para transferências entre empresas de nosso grupo.</p>
            <p id="additionalprotections"><strong>Proteções Adicionais para Residentes de Determinadas Regiões</strong><br /> Como uma empresa global, a Cummins aplicou medidas adicionais de proteção de dados e defende direitos adicionais de indivíduos de acordo com os requisitos regionais e locais. Por exemplo, para residentes da União Europeia/Espaço Econômico Europeu, a Cummins cumpre o Regulamento Geral sobre a Proteção de Dados (GDPR) 2016/679 da UE. Da mesma forma, nos Estados Unidos, a Cummins cumpre a Lei de Privacidade do Consumidor da Califórnia de 2018 (AB 375, Código Civil da Califórnia Título 1.81.5, Seção 1798.100 et seq.) para residentes da Califórnia, bem como outras leis de proteção ao consumidor similares que tenham sido promulgadas em outros estados. Conforme aplicável, a Cummins também cumpre outros requisitos locais de proteção de dados nos países em que opera, como os das Américas do Norte e do Sul, da região Ásia-Pacífico, do leste da Europa, do Oriente Médio, da África e de outros lugares.</p>
            <p>Para residentes da Califórnia, consulte a Lei de Privacidade do Consumidor da Califórnia — Aviso de Privacidade sobre Dados do Consumidor.</p>
            <p>Para residentes da União Europeia/Espaço Econômico Europeu, o GDPR oferece os seguintes direitos (com algumas exceções):</p>
            <ul><li>O direito de se manter informado: você tem o direito de obter informações claras, transparentes e de fácil compreensão sobre como usamos seus dados pessoais e sobre seus direitos;</li>
              <li>O direito de acesso: você tem o direito de acessar os dados pessoais que a Cummins mantém sobre você;</li>
              <li>O direito de retificação: você tem o direito de retificar seus dados pessoais se eles estiverem incorretos ou desatualizados e/ou completá-los se estiverem incompletos.</li>
              <li>Direito ao apagamento/direito de ser esquecido: você tem o direito de ter seus dados pessoais apagados ou excluídos. Observe que este não é um direito absoluto, já que podemos ter motivos legais ou legítimos para manter seus dados pessoais;</li>
              <li>O direito de se opor ao marketing direto: você pode cancelar a assinatura ou optar por não receber nossas comunicações de marketing direto a qualquer momento. Você pode fazer isso clicando no link de cancelamento de assinatura em qualquer e-mail ou comunicado que enviarmos a você. Você também pode solicitar o recebimento de comunicados não personalizados sobre nossos produtos e serviço;</li>
              <li>O direito de retirar o consentimento a qualquer momento para processamento de dados com base em consentimento: você pode retirar seu consentimento para nosso processamento de seus dados quando esse processamento for baseado em consentimento; e</li>
              <li>O direito à portabilidade de dados: você tem o direito de mover, copiar ou transferir dados de nossa base de dados para outra. Isso aplica-se somente a dados que você tenha fornecido cujo processamento seja baseado em um contrato ou em seu consentimento e cujo processamento seja realizado automaticamente.</li>
            </ul><p>Você também tem o direito de entrar em contato com a autoridade de proteção de dados de seus país para registrar uma queixa contra as práticas de proteção de dados e privacidade da Cummins.</p>
            <p>Fale conosco por <a href="mailto:dataprivacy@cummins.com" rel="noreferrer">dataprivacy@cummins.com</a> para exercer esses direitos ou se você tiver alguma pergunta ou queixa a respeito do processamento de seus dados pessoais.</p>
            <p><strong>Certificação do Acordo Privacy Shield UE-EUA</strong><br /> A Cummins Inc. segue os princípios da estrutura Privacy Shield UE-EUA do Departamento de Comércio dos EUA para seus recursos humanos e dados pessoais de clientes. No entanto, à luz da decisão do Tribunal de Justiça da União Europeia no Caso C-311/18 ("Schrems II"), a Cummins descontinuou seu uso da estrutura Privacy Shield como base jurídica para transferências de dados pessoais. Mas a Cummins continua certificada com essa estrutura como parte do compromisso geral da empresa de garantir práticas de proteção de dados compatíveis e responsáveis nos países onde nós atuamos. Para saber mais sobre o programa Privacy Shield e para ver nossa certificação, acesse o site Privacy Shield do departamento de comércio dos EUA em: <a href="https://www.privacyshield.gov/list" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/list</a> ou, consulte nosso Privacy Shield Notice.</p>
            <p><strong>Cookies e Outras Técnicas de Rastreamento Anônimo na Web</strong><br /> Como a maioria dos sites, também podemos coletar informações on-line por meio de "cookies", que são fragmentos de informações armazenadas diretamente no computador que você está usando.Os Cookies podem conter uma variedade de informações, como uma simples contagem de quantas vezes você visitou nosso site ou informações que permitem personalizar nosso site para seu uso.Usamos cookies para exibir informações com mais eficácia a você e para coletar dados sobre o uso de nosso site.Você sempre pode configurar seu navegador para recusar todos os cookies ou para alertá-lo quando um cookie for enviado a você.Isso lhe dará maior controle sobre a aceitação de cookies em seu computador.Entretanto, é possível que algumas partes deste site não funcionem adequadamente ou executem mais lentamente se você escolher rejeitar os cookies.</p>
            <p><strong>Nosso Compromisso com a Privacidade Infantil</strong><br /> Este website não tem a finalidade de coletar o manter qualquer informação sobre crianças, conforme definido pelas leis locais nos países em que operamos. Como regra geral, nossos websites não são destinados a pessoas menores de 13 anos. Se soubermos que coletamos por engano qualquer informação de qualquer pessoa menor de 13 anos ou da idade limite no país onde a criança resida, nós a excluiremos imediatamente, exceto para responder a uma única pergunta ou consulta da pessoa ou dos pais ou responsáveis legais da pessoa.</p>
            <p><strong>Links para Outros Websites</strong><br />Nosso site contém links para outros websites que não pertencem nem são operados pela Cummins. Você deve revisar cuidadosamente as políticas de privacidade de outros websites, pois nós não podemos controlar e não somos responsáveis pelas políticas ou práticas de privacidade de websites de terceiros que não nos pertencem.</p>
            <p><strong>Retenção de Dados Pessoais</strong><br /> A Cummins mantém informações pessoais de acordo com os requisitos legais e necessidades comerciais e, posteriormente, destrói as informações de forma segura.</p>
            <p><strong>Alterações a Esta Política de Privacidade</strong><br /> À medida que continuamos a fornecer serviços adicionais e que as leis e regulamentos de privacidade evoluem, pode ser necessário revisar ou atualizar nossa Política de Privacidade. Se decidirmos alterar nossa política, publicaremos essas alterações em nossa Página Inicial, de modo que nossos usuários sempre tenham conhecimento de quais informações nós coletamos, como as usamos e sob que circunstâncias, se for o caso, nós as divulgamos. Se, a qualquer momento, quisermos usar informações pessoais de uma maneira que seja diferente ou que não seja razoavelmente dedutível a partir do que foi declarado em nossas políticas no momento da coleta, notificaremos os usuários com antecedência e buscaremos seu consentimento conforme juridicamente exigido. Os usuários poderão escolher se usaremos ou não suas informações desta maneira diferente.</p>
            <p>Nós sempre usaremos as informações de acordo com a política de privacidade sob a qual as informações foram coletadas.</p>
            <p><strong>Fale conosco</strong><br /> Se você tem perguntas sobre os Procedimentos ou práticas de Privacidade da Cummins ou acredita que suas informações foram indevidamente gerenciadas, poderá entrar em contato conosco enviando-nos um e-mail para <a href="mailto:legal.function@cummins.com" rel="noreferrer">legal.function@cummins.com</a>, ou enviando correspondência para Cummins Inc.Global Headquarters, 500 Jackson Street, Columbus, IN 47201. A Cummins implementou um processo de solução de controvérsias para controvérsias relacionadas com privacidade ou segurança e não faz retaliação contra indivíduos que nos contatam com uma controvérsia que acreditam ser legítima.Indivíduos que acreditam que sua controvérsia não foi satisfatoriamente resolvida  pela empresa também têm o direito de apresentar reclamações junto aos órgãos de supervisão competentes para proteção de dados.</p>
            <p><small>© 6 de outubro de 2021 Cummins Inc. © Todos os direitos reservados.</small></p>
          </CollapseItem>
          <CollapseItem title="Aviso de Privacidade do Website do Acordo Privacy Shield UE-EUA">
            <p>Este Aviso de Privacy Shield do website destina-se a informá-lo sobre a adesão e a conformidade da Cummins Inc. com o Acordo Privacy Shield UE-EUA conforme estabelecido pelo Departamento de Comércio dos EUA.</p>
            <br /><br />
            <p><u>Escopo:</u> A Cummins fez a certificação e confirmou sua adesão ao Privacy Shield UE-EUA para informações pessoais de seus recursos humanos e clientes relativas a residentes da União Europeia (UE), do Espaço Econômico Europeu (EEE) e do Reino Unido (RU). No entanto, à luz da decisão do Tribunal de Justiça da União Europeia no Caso C-311/18 ("Schrems II"), a Cummins descontinuou seu uso da estrutura Privacy Shield como base jurídica para transferências de dados pessoais. Mas a Cummins continua certificada com essa estrutura como parte do compromisso geral da empresa de garantir práticas de proteção de dados compatíveis e responsáveis nos países onde nós atuamos.</p>
            <br /><br />
            <p>Os princípios do Privacy Shield que a Cummins apoia em relação à sua Certificação de Privacidade são: Aviso; Escolha (Consentimento); Responsabilização para Transferência Externa; Segurança; Integridade dos Dados e Limitação de Finalidade; Acesso; e Recurso, Execução e Responsabilidade. Para saber mais sobre o programa Privacy Shield e para ver nossa certificação, acesse o site Privacy Shield do departamento de comércio dos EUA em <a href="https://www.privacyshield.gov/list" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/list</a>.</p>
            <br /><br />
            <p><u>Conflitos:</u> Se houver qualquer conflito entre os termos do Aviso de Privacidade e os Princípios do Privacy Shield, prevalecerão os Princípios do Privacy Shield, conforme modificado pela decisão do Tribunal de Justiça da UE no caso Schrems II.</p>
            <br /><br />
            <p><u>Controvérsias:</u> Em conformidade com os princípios do Privacy Shield, a Cummins se comprometeu a cooperar com as Autoridades de Supervisão da UE em relação a reclamações não resolvidas do Privacy Shield relativas a informações pessoais de recursos humanos ou clientes da empresa. Indivíduos que acreditam que suas informações pessoais foram manuseadas incorretamente pela Cummins em relação aos compromissos da empresa com o Privacy Shield têm o direito de contatar as Autoridades de Supervisão da UE para mais informações ou para apresentar uma reclamação, e podem fazer isso gratuitamente. Indivíduos da U.E./E.E.E. e do Reino Unido com consultas ou reclamações relativas às políticas ou práticas do Privacy Shield devem primeiro contatar a Cummins em:</p>
            <br /><br />
            <p>E-mail: <a href="mailto:legal.function@cummins.com">legal.function@cummins.com</a><br /> Endereço de e-mail: Cummins Inc., Law Department-Privacy Shield Team, 301 East Market Street, 5º andar, Indianapolis, Indiana EUA 46204</p>
            <br /><br />
            <p>Salvo circunstâncias imprevistas, a Cummins responderá ao indivíduo que enviou a reclamação dentro de 45 (quarenta e cinco) dias do recebimento.</p>
            <br /><br />
            <p><u>Supervisão e Fiscalização Regulatória:</u> Em relação à sua certificação Privacy Shield, a Cummins está sujeita à autoridade investigatória e de fiscalização da Comissão Federal de Comércio dos EUA e das Autoridades de Supervisão europeias pertinentes. </p>
            <br /><br />
            <p><u> direito à arbitragem vinculativa: </u> Em circunstâncias limitadas e de acordo com o Privacy Shield Framework, indivíduos da U.E./E.E.E. e do Reino Unido também podem buscar arbitragem vinculativa antes de um painel de Privacy Shield.</p>
            <br /><br />
            <p><u> direitos dos indivíduos para acessar seus dados: </u> Os indivíduos da U.E./E.E.A. e do Reino Unido têm o direito de acessar informações pessoais sobre eles e de limitar o uso e a divulgação de suas informações pessoais. Em algumas situações, como quando os dados estão incorretos ou foram coletados inapropriadamente, os indivíduos também têm o direito de solicitar alteração ou apagamento de seus dados. A Cummins está comprometida a respeitar e manter esses direitos. Se desejar exercer esses direitos, você pode nos enviar uma correspondência para 301 East Market Street, 5th floor, Indianapolis, Indiana USA 46204, ou enviar um e-mail para <a href="mailto:legal.function@cummins.com">legal.function@cummins.com</a>. Existem algumas limitações sobre esses direitos, conforme descrito no acordo Privacy Shield.</p>
            <br /><br />
            <p><u> pedidos de aplicação da lei: </u> A Cummins pode ser obrigada a divulgar informações pessoais em resposta a solicitações legais de autoridades públicas, inclusive conformidade com os requisitos de segurança nacional ou de aplicação da lei. Ao fazer isso e na medida do permitido em relação à situação específica, a Cummins toma medidas razoáveis para equilibrar os direitos dos indivíduos com os das agências solicitantes.</p>
            <br /><br />
            <p><u> Updates: </u> Essa política pode ser atualizada de tempos em tempos, como em relação a modificações na estrutura do Privacy Shield, expectativas regulatórias ou mudanças dentro da Cummins. Essas alterações não afetarão negativamente as proteções do Privacy Shield que tenham sido implantadas pela Cummins.</p>
            <br /><br />
            <p><small>Em vigor a partir de 6 de outubro de 2020. Copyright de setembro de 2020. Todos os direitos reservados.</small></p>
            <br /><br />
          </CollapseItem>
          <CollapseItem title="Aviso de proteção de dados telemáticos">
            <p><u>Finalidade da coleta</u>: aparelhos telemáticos têm a capacidade de identificar falhas associadas a peças essenciais dos equipamentos ou veículos comerciais e também podem identificar quando manutenção e/ou reparos são necessários. Isso, por sua vez, ajuda a assegurar o bom desempenho operacional e ajuda a minimizar a possibilidade de reparos inesperados ou catastróficos, junto a potenciais problemas de segurança.</p>
            <br /><br />
            <p><u>Informações pessoais coletadas</u>: quando o aparelho telemático for ativado no equipamento ou veículo comercial, ele registrará e analisará determinados dados relacionados à operação apropriada. Os elementos de dados específicos coletados são limitados a aqueles especificados nas normas do setor reconhecidas internacionalmente para veículos e equipamentos comerciais. Eles podem incluir, entre outros, velocidade do motor e do veículo, temperatura de entrada, temperatura de escape, pressão do ambiente, pressão e temperatura do pneu, códigos de falha em vários componentes, tais como freios, transmissão e bateria, status de integridade da bateria e quaisquer irregularidades que são identificadas por meio desses códigos (como nível baixo de óleo, ajuste necessário, fluido de transmissão baixo, entre outros).</p>
            <br /><br />
            <p>Para associar os dados telemáticos com um equipamento ou veículo móvel comercial específico, a Cummins também coleta outras informações, como número de identificação do equipamento ou veículo comercial, número de identificação do motor, fabricação, modelo e ano do veículo ou equipamento e dados de trajeto muito limitados (por exemplo, localização do GPS, hora e data dos deslocamentos, endereço, velocidade, taxas de aceleração/desaceleração, padrões de frenagem, entre outros). Os dados que são coletados também pode incluir informações de condução que podem ser associadas ao desempenho ou segurança do equipamento ou veículo móvel comercial (como aceleração brusca, frenagem brusca ou curva brusca) na extensão associada com o desempenho ou a segurança do veículo.</p>
            <br /><br />
            <p>A Cummins limita o uso de dados (localização) de GPS ao que é relevante para fins de desempenho, manutenção e melhoria, tais como identificar condições externas (por exemplo, tempo, condições da estrada, altitude e velocidade) que podem causar impacto no veículo ou equipamento comercial (incluindo em relação a falhas e reparos). Os dados de GPS nunca são usados ou compartilhados com nenhum tipo de monitoramento pessoal de trajetos ou perfil do motorista.</p>
            <br /><br />
            <p><u>Processamento de dados telemáticos</u>: a Cummins processa os dados telemáticos coletados para auxiliar na identificação de falhas e problemas de manutenção relacionados ao veículo ou equipamento comercial. Isso inclui enviar feedback em tempo real para parceiros de telemática que contratam a Cummins para esse serviço e/ou o proprietário/locador do veículo ou equipamento comercial. Os dados também podem ser usados para finalidades adjacentes, como melhoria do produto e da qualidade, facilitar reparos, suporte ao cliente e fins regulamentares, legais e de conformidade adjacentes.</p>
            <br /><br />
            <p><u>Outras informações</u>: consulte as outras seções desta Política de Privacidade para ver informações adicionais sobre as práticas da Cummins relacionadas a dados pessoais de telemática (e outros), por exemplo, em relação a segurança, transferências transfronteiriças, informações específicas para determinadas regiões, direitos individuais, retenção de dados e quaisquer disputas.</p>
            <br /><br />
            <p>Pessoas com dúvidas ou preocupações relacionadas à coleta de dados telemáticos pela Cummins também podem entrar em contato conosco em <a href="mailto:dataprivacy@cummins.com">dataprivacy@cummins.com</a> para obter assistência.</p>
            <br /><br />
          </CollapseItem>
          <CollapseItem title="Aviso de proteção de dados de ferramentas digitais">
            <p><u>Finalidade da coleta</u>: nossas ferramentas digitais incluem ferramentas e aplicativos on-line que são desenvolvidos para ajudar os clientes com eventos de serviço relacionados a veículos e equipamentos. Entendemos o inconveniente e o tempo perdido associados ao tempo de inatividade e manutenção, e buscamos minimizar os inconvenientes por meio dessas ferramentas. Entre outras coisas, as ferramentas ajudam você a localizar, com rapidez, serviços de reparo próximos (ou chamar o atendimento até onde você está), simplificando o processo de diagnóstico para identificar a causa da falha ou mau funcionamento e colocá-lo de volta em operação o mais rápido possível.</p>
            <br /><br />
            <p><u>Informações pessoais coletadas</u>: os elementos de dados específicos coletados por meio das ferramentas digitais da Cummins variam com base na ferramenta e nas necessidades específicas do cliente. Elas incluem, entre outras, seu nome e informações de contato, informações relacionadas ao veículo ou equipamento comercial em questão, dados de localização (em relação a localizar um centro de reparos próximo ou chamar o serviço até onde você está), avaliações de diagnóstico relacionadas a códigos de falha ou reparos, incluindo causa raiz e potenciais fatores que impactam o mau funcionamento, informações de pagamento para quaisquer reparos ou assinaturas desses serviços, e feedback do cliente via pesquisas (se aplicável).</p>
            <br /><br />
            <p><u>Processamento das informações coletadas</u>: a Cummins processa os dados coletados de qualquer ferramenta ou aplicativo digital específico no qual você se registra com relação às suas necessidades específicas. Isso inclui conduzir os diagnósticos necessários em seu veículo ou equipamento comercial, atender a solicitações de reparo e serviço, fornecer informações a você sobre endereços de serviço próximos, agendar um horário e ir até seu endereço para fornecer diagnóstico e reparos. Os dados também podem ser usados para finalidades adjacentes, como melhoria do produto e da qualidade, feedback e suporte ao cliente e fins regulamentares, legais e de conformidade adjacentes.</p>
            <br /><br />
            <p><u>Outras informações</u>: consulte as outras seções desta Política de Privacidade para ver informações adicionais sobre as práticas da Cummins relacionadas a dados pessoais de ferramentas digitais (e outros), por exemplo, em relação a segurança, transferências transfronteiriças, informações específicas para determinadas regiões, direitos individuais, retenção de dados e quaisquer disputas.</p>
            <br /><br />
            <p>Pessoas com dúvidas ou preocupações relaciondas à coleta de dados pessoais pela Cummins em nossas ferramentas digitais também podem entrar em contato conosco em <a href="mailto:dataprivacy@cummins.com">dataprivacy@cummins.com</a> para obter assistência.</p>
            <br /><br />
            <p><small>© 6 de outubro de 2021 Cummins Inc. © Todos os direitos reservados.</small></p>
            <br /><br />
          </CollapseItem>
          <CollapseItem title="Termos de Uso">
            <p>
              <b>Aviso: Leia este documento cuidadosamente antes de usar este website. Não use este website se você não concorda com os termos e condições presentes neste documento. O uso deste website significa concordância com estes Termos de Uso.</b><br />
              &nbsp;<br /><strong>Uso do Site</strong><br /> A Cummins Inc. (suas subsidiárias, joint ventures e coligadas, e também seus distribuidores autorizados) o autorizam a ver e fazer download de informações eletrônicas designadas neste Website ("Site"). Essas informações eletrônicas ("Informações da Cummins") podem consistir em software, informações técnicas, literatura sobre produtos e outros materiais. Você deve manter todos os avisos de direitos autorais, marcas registradas e outros avisos de propriedade presentes nos originais das Informações da Cummins e em quaisquer cópias de Informações da Cummins. Você não pode modificar as Informações da Cummins obtidas deste Site de nenhuma forma nem reproduzir ou exibir publicamente, transmitir ou distribuir, ou de outra forma usar as Informações da Cummins para nenhuma finalidade comercial. Para os fins deste Termos, qualquer uso de Informações da Cummins em qualquer outro Website ou ambiente de computador em rede (incluindo, entre outros, publicação de Informações da Cummins em uma Intranet local ou Extranet) para qualquer finalidade é proibida sem a expressa permissão escrita da Cummins. As Informações da Cummins neste Site têm direitos autorais e qualquer uso não autorizado de quaisquer Informações da Cummins localizadas neste Site pode violar as leis de direitos autorais, marca registrada e outras leis. Se você não concordar com estes termos sua autorização para uso deste Site será automaticamente encerrada e você precisa destruir imediatamente quaisquer Informações da Cummins obtidas do site.<br />
              &nbsp;<br /><strong>Uso Apropriado, Ético e em Conformidade com a Lei</strong><br /> Ao usar este Site, você concorda em não interromper nem interferir com a segurança, ou de outra forma abusar do Website ou de quaisquer serviços, recursos de sistema, contas, servidores ou redes conectados ou acessíveis por meio do Site ou de Websites coligados ou vinculados; b) não interromper ou interferir no uso por nenhum outro usuário do Site, ou de Websites coligados ou vinculados; (c) não carregar, publicar ou de outra forma transmitir por meio do ou no Site, vírus ou quaisquer outros arquivos danosos, disruptivos ou destrutivos; (d) não usar nem tentar usar contas, serviços ou sistemas de outros sem a autorização da Cummins nem criar ou usar falsa identidade neste Site; (e) não transmitir por meio do ou no Site, spam, correntes, lixo eletrônico ou qualquer tipo de e-mail em massa não solicitado a pessoas ou entidades que não tenham concordado em participar desses envios de e-mails; (f) não divulgar seu nome de usuário ou senha a terceiros quer no Site ou fora dele; (g) não tentar obter acesso não autorizado ao Site ou a partes do Site que tenham restrições ao acesso geral; (h) cumprir todas as leis e exigências de uso deste Site, inclusive aquelas aplicáveis aos dados nele contidos.<br />
              &nbsp;<br /><strong>Informações de Catálogo</strong><br /> As informações de catálogo são fornecidas para conveniência de nossos clientes e são tão precisas quanto possível. Entretanto, o fabricante não dá garantia, expressa ou implícita, em relação à precisão ou disponibilidade dos produtos listados. Todas as outras informações sobre especificações e aplicação são tomadas de informações autorizadas. Foram feitos todos os esforços para fornecer as informações de instalação originais de fábrica. Muitos fabricantes oferecem opções de motores e/ou filtros em modelos específicos; os fabricantes também podem fazer alterações de linha de produção em um modelo durante o ano de produção; os proprietários podem modificar o equipamento. A Cummins não se responsabiliza por usos incorretos.<br />
              &nbsp;<br /><strong>Propriedade Intelectual</strong><br /> Os nomes, imagens e logotipos que identificam a Cummins, ou terceiros e seus produtos e serviços, estão sujeitos a direitos autorais, direitos de projeto e marcas registradas da Cummins e/ou de terceiros. Nenhum conteúdo destes termos será interpretado como conferindo por implicação, preclusão ou de outra forma qualquer licença ou direito de uso de qualquer marca registrada, patente, direito sobre projeto ou direitos autorais da Cummins ou de qualquer outro terceiro.<br />
              &nbsp;<br /><strong>Envios do Usuário</strong><br /> Qualquer material, informação ou ideia que você transmita ou publique neste Site por qualquer meio (inclusive, entre outros, por e-mail enviado à Cummins usando links fornecidos neste site) será tratado como não confidencial e não exclusivo e poderá ser disseminado ou usado pela Cummins ou suas coligadas para qualquer finalidade, inclusive, entre outros, para desenvolver, fabricar e comercializar produtos. Você está proibido de publicar ou transmitir deste ou para este Site qualquer material ilegal, ameaçador, ofensivo, difamatório, obsceno, escandaloso, inflamatório, pornográfico ou profano, ou qualquer material que possa dar origem a qualquer responsabilidade civil ou criminal nos termos da lei. O usuário não deve publicar nenhum material que viole direitos autorais, marcas registradas ou outros direitos de propriedade (inclusive direitos de publicidade e privacidade). O usuário indenizará a Cummins por todo e qualquer dano resultante de ações ou publicações do usuário que violem quaisquer dessas políticas. A Cummins se reserva o direito de proibir publicações adicionais por qualquer usuário pela violação dessas normas.<br />
              &nbsp;<br /><strong>Links para Outros Websites</strong><br /> Os links para Websites de terceiros a partir deste site devem ter aprovação específica antecipada da Cummins. Você não está autorizado a vincular nenhum outro Website sem nossa aprovação. Quaisquer links aprovados para Websites não mantidos pela Cummins são fornecidos somente como conveniência aos nossos clientes e visitantes. Se você usar esses links, você Cummins não controla e não é responsável por nenhum desses sites ou seus conteúdos. A Cummins não endossa nem faz declarações sobre eles, nem dá informações sobre software ou outros produtos ou materiais lá encontrados, nem sobre resultados que possam ser obtidos com seu uso. Se decidir acessar quaisquer sites de terceiros vinculados a este Site, você o fará por sua conta e risco.<br />
              &nbsp;<br /><strong>Ressalva</strong><br /> Os materiais fornecidos neste site são fornecidos "no estado em que se encontram" sem nenhuma garantia, expressa ou implícita, inclusive garantias de comerciabilidade, adequação a uma finalidade específica ou não violação de propriedade intelectual. A Cummins também não garante a precisão e completude dos materiais deste site. A Cummins pode fazer alterações nos materiais deste site, ou nos produtos e preços descritos, a qualquer tempo sem aviso prévio. Os materiais deste site podem ficar desatualizados, e a Cummins não se compromete a atualizar os materiais deste site. Informações publicadas neste site podem se referir a produtos, programas ou serviços que não estão disponíveis em seu país ou região. Consulte a Cummins diretamente para informações relativas a produtos, programas e serviços que podem estar disponíveis para você. As leis aplicáveis podem não permitir as exclusões das garantias implícitas, portanto, as exclusões acima podem não se aplicar a você.<br />
              &nbsp;<br /><strong>Limitação de Responsabilidade</strong><br />Em nenhuma circustância a Cummins, seus fornecedores ou outros terceiros mencionados neste site serão responsáveis por quaisquer danos (incluindo, entre outros, danos consequentes, e aqueles resultantes de perda de lucro, perda de dados ou interrupção dos negócios) oriundos do uso, inabilidade ao usar ou dos resultados do uso deste site, de quaisquer websites vinculados a este site, de quaisquer websites vinculados a este site, ou dos materiais e informações contidos em todo ou qualquer desses sites, com base em garantia, contrato, ato ilícitio ou qualquer outra teoria legal e se avisada ou não da possibilidade de ocorrência desse dano. Se o uso que você fizer dos materiais ou informações deste site resultar na necessidade de manutenção, reparo ou correção de equipamentos ou dados, você assume todos os custos resultantes. A lei aplicável pode não permitir a exclusão ou limitação de danos incidentais ou consequentes, portanto, a limitação ou exclusão acima pode não se aplicar a você. Acreditamos que os dados e informações apresentados neste website são precisos, mas o usuário não deve confiar neles para nenhuma finalidade. Renunciamos expressamente a toda e qualquer responsabilidade pelo conteúdo ou por quaisquer omissões deste website, incluindo quaisquer imprecisões, erros ou declarações equivocadas nos dados ou informações. A Cummins rejeita qualquer responsabilidade pelo uso de produtos Cummins em aplicações diferentes daquelas para as quais foram projetados.<br />
              &nbsp;<br /><strong>Leis Aplicáveis</strong><br /> Este Site é administrado pela Cummins baseada em Columbus, Indiana. A Cummins não faz declarações de que os materiais deste Site são apropriados ou estão disponíveis para uso fora dos Estados Unidos, e fica proibido o acesso a eles a partir de territórios onde seu conteúdo é ilegal. Você não pode usar nem exportar ou reexportar os materiais deste Site ou qualquer cópia ou adaptações que violem quaisquer leis ou regulamentos aplicáveis incluindo, entre outros, as leis e regulamentos sobre exportação dos E.U.A. Se você optar por acessar este Site de fora dos Estados Unidos, o fará por sua própria iniciativa e será responsável pela conformidade com as leis locais aplicáveis. Esses Termos serão regidos e interpretados de acordo com as leis do Estado de Indiana, sem considerar os princípios relativos a conflitos de leis.<br />
              &nbsp;<br /><strong>Informações Gerais</strong><br /> A Cummins reserva-se o direito de modificar esses Termos a qualquer tempo. Os Termos contidos nesta página são vinculantes, portanto, você deve revisá-los com frequência. Os termos relacionados neste documento podem ser sobrepostos por termos específicos em outros locais do site.<br />
              &nbsp;<br /><strong>Formulários de Pedidos, Formulários de Registro e E-mail</strong><br /> Este site usa formulários para gerar e-mails, os quais são então usados para enviar consultas sobre pedidos, enviar informações de registro e outros processos comerciais. A Cummins declara explicitamente que esses formulários não constituem nenhum acordo entre a Cummins e a pessoa que está enviando o formulário. A Cummins renuncia expressamente a toda e qualquer responsabilidade em relação à entrega e processamento desses formulários. Se sua necessidade for urgente, entre em contato com a Cummins diretamente por telefone. Os e-mails enviados à Cummins são transmitidos via Internet. A Cummins não é responsável por quaisquer atrasos causados em razão de problemas com a entrega, processamento ou segurança e privacidade de e-mails da Internet.<br />
              &nbsp;<br /><strong>Política de Privacidade</strong><br /> A Cummins valoriza sua privacidade. Nós não revenderemos nenhuma de suas informações. Podemos utilizar cookies para determinar seu uso do website. Usaremos as informações que você nos dá para ajudar a fornecer produtos e serviços melhores. Podemos divulgar, processar, transmitir ou reutilizar as informações no nosso grupo de empresas Cummins e nossos distribuidores para fornecer melhor suporte ao cliente. Podemos contatá-lo periodicamente com informações em relação a ofertas específicas, promoções, novos produtos e serviços.<br /> Ao utilizar este site você manifesta sua concordância com a Política de Privacidade da Cummins. Se não concordar com esta política, por favor, não utilize nosso website. Reservamo-nos o direito, a nosso critério, de alterar, modificar, adicionar ou remover partes desta política a qualquer tempo. Verifique esta página periodicamente quanto a alterações. Seu uso continuado do website da Cummins em seguida à publicação de alterações a esses termos significará que você aceita essas alterações.
            </p>
            <p><small>Atualização em fevereiro de 2017</small></p>
          </CollapseItem>
          <CollapseItem title="Aviso do Site sobre Cookies e Declaração de Divulgação">
            <p>Esta Declaração de Divulgação e Aviso do Website se aplica a Websites pertencentes e operados pela Cummins Inc. assim como suas subsidiárias, joint ventures, coligadas e quaisquer distribuidores autorizados. Ele se destina a informar a você sobre o uso de cookies, analítica e outras tecnologias da web pela Cummins em seus websites, assim como suas opções de rejeitar o uso desses itens.<br />
              &nbsp;<br /><strong>O que são cookies?</strong><br /> Cookies são pequenos fragmentos de dados enviados de um website e armazenados em um navegador da web do usuário. Quando o usuário navegar pelo mesmo website no futuro, os cookies notificarão o website sobre as atividades anteriores do usuário. Cookies não ameaçam seu computador de nenhuma forma, e alguns são automaticamente excluídos depois de um certo período de tempo, como seis meses.<br />
              &nbsp;<br /><strong>A Cummins usa cookies em seus websites?</strong><br /> Sim. Como virtualmente todos os websites, usamos certos tipos de cookies em nossos sites. Isso permite que coletemos informações não pessoais por meio de uma variedade de tecnologias. Isso nos ajuda a identificar visitantes que retornam ao nosso site e nos diz coisas como quantos usuários visitaram nosso site, as páginas que foram acessadas, e se houve algum problema técnico no carregamento das páginas ou na navegação pelo nosso site. Ao coletar essas informações, tomamos conhecimento de quais partes de nossos sites são as mais interessantes e valiosas para nossos usuários, e podemos monitorar o interesse geral em nossos websites. Isso também nos permite identificar problemas técnicos com nosso site para que possamos corrigi-los imediatamente. Em contrapartida, isso nos ajuda a atualizar nossos websites e melhorar nossas ofertas aos nossos visitantes.<br />
              &nbsp;<br /><strong>Eu sou obrigado a aceitar os cookies para usar os websites da Cummins?</strong><br /> Não. Você pode desativar o uso de cookies por websites, inclusive os nossos, ao desligar a função de cookies em seu navegador. Entretanto, algumas partes de nosso site assim como da maioria dos sites, não funcionarão corretamente se esta for a sua opção. Por exemplo, se você decidir comprar alguma coisa por meio de nosso site ou inserir uma senha para acessar uma área restrita, você não conseguirá fazê-lo a menos que o site possa autenticá-lo por meio de cookies.<br />
              &nbsp;<br /><strong>Qual informação você recebe sobre mim por meio de cookies?</strong><br /> Existem quatro categorias gerais de cookies que são usadas em websites:</p>

            <ul><li><strong style={{display: "block"}}>Cookies estritamente necessários (ou essenciais).</strong> Esses cookies são essenciais para permitir que você se movimente em torno de um website e use seus recursos, tais como serviços que você tenha solicitado. Sem esses cookies, esses serviços não estarão disponíveis para você. Isso inclui, por exemplo, navegar como um usuário registrado, acessar áreas seguras do site ou fazer uma compra por meio do site. Como todos os websites públicos, nosso site usa cookies estritamente necessários.</li>
              <li><strong style={{display: "block"}}>Cookies de desempenho.</strong> Esses cookies coletam informações sobre como os visitantes usam um website, por exemplo, quais páginas os visitantes vão com mais frequência e se obtêm mensagens de erro de páginas da Web. Esses cookies não coletam informações que identificam um visitante. Todas as informações que esses cookies coletam são agregadas e, portanto, anônimas. São usadas somente para melhorar como o website funciona. Como quase todos os websites, usamos cookies de desempenho em nossos sites.</li>
              <li><strong style={{display: "block"}}>Cookies de funcionalidade.</strong> Esses cookies permitem que o website se lembre das escolhas que você fez (como seu nome de usuário, idioma ou região em que você está) e fornecem recursos mais pessoais e melhorados. Isso permite que você tenha uma experiência melhor ao usar o site. As informações que esses cookies coletam podem ser anônimas e eles não podem rastrear sua atividade de navegação em outros websites. Como quase todos os websites, usamos cookies de funcionalidade em nossos sites.</li>
              <li><strong style={{display: "block"}}>Cookies direcionados ou de publicidade.</strong> Esses cookies coletam informações sobre seus hábitos de navegação para fazer anúncios relevantes para você e seus interesses. Eles são também usados para limitar o número de vezes que você vê um anúncio assim como ajudam a medir a eficácia da campanha de publicidade. Eles são geralmente colocados por redes de publicidade com a permissão do operador do website. Eles lembram que você visitou um website e essa informação é compartilhada com outras organizações tais como anunciantes. Frequentemente cookies direcionados ou de publicidade serão vinculados à funcionalidade do site fornecida pela outra organização. A Cummins não usa cookies de publicidade em seus websites e não permite que terceiros usem esses tipos de cookies em seus sites.</li>
            </ul><p>&nbsp;<br /><strong>O que acontece se eu escolher não desativar cookies em meu computador?</strong><br /> A não ser que você desative essas tecnologias, ao usar nosso website e serviços on-line, você concorda que podemos colocar esses tipos de cookies e tecnologias relacionadas em seu dispositivo.<br />
              &nbsp;<br /><strong>O que são web beacons?</strong><br /> Alguns tipos de cookies coletam informações agregadas que nos ajudam a melhorar nossos websites ao fornecer informações, por exemplo, sobre erros de carregamento e as páginas da web mais visitadas. Algumas páginas de nosso site podem conter "web beacons" (também conhecidos como Internet tags, pixel tags e clear GIFs). Usamos web beacons para ajudar a exibir conteúdo aos visitantes e para gerar estatísticas relativas ao tráfego e tendências na web. Web beacons não podem identificá-lo como indivíduo.<br />
              &nbsp;<br /><strong>O que são cookies de publicidade?</strong><br /> Cookies de publicidade são cookies direcionados a um indivíduo para comprar alguns itens com base em suas práticas de navegação na web. Por exemplo, se você pesquisar tacos de golfe na web, e o website que você usa (ou o motor de busca) tiver cookies de publicidade instalados, você verá anúncios pop-up de outros sites que vendem produtos de golfe ou têm conteúdo relacionado a golfe. A Cummins não usa cookies de publicidade em seus websites e não permite que terceiros usem esses tipos de cookies em seus sites.<br />
              &nbsp;<br /><strong>Como eu opto por não aceitar (retirar o consentimento) seu uso de cookies ou tecnologias web similares?</strong><br /> Você pode não aceitar nosso uso que cookies da seguinte forma: <br /> Você pode configurar seu navegador para impedir o uso de cookies. Com isso, você ainda poderá navegar em nosso site mas pode não conseguir usar muitos de seus recursos nem fazer compras.<br />
              &nbsp;<br /><strong>A Cummins permite que terceiros instalem cookies ou tecnologias web semelhantes em seus websites?</strong><br /> Como regra geral, não permitimos que nenhum terceiro (como anunciantes) instalem cookies em nossos websites nem se envolvam em publicidade comportamental. Entretanto, em alguns de nossos websites, tais como nossas páginas de redes sociais, é possível que indivíduos tenham carregado conteúdo de terceiros, como vídeos do YouTube ou de outros locais, ou conteúdo incorporado e ferramentas de compartilhamento de outros sites de terceiros. Como nós não controlamos a disseminação de cookies por quaisquer sites não pertencentes ou operados pela Cummins, você deve verificar o(s) website(s) relevante(s) de terceiros para mais informações sobre esses cookies, e se e como você pode recusar-se a recebê-los. De modo geral, entretanto, você pode desativar cookies de terceiros ao ajustar suas configurações de navegador. As instruções podem ser encontradas em <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">www.aboutcookies.org</a>.</p>
          </CollapseItem>

          <CollapseItem title="Lei de privacidade do consumidor da Califórnia-aviso de privacidade de dados do consumidor">
            <br />
            <p>Este aviso de privacidade de dados descreve como a Cummins Inc. e suas empresas afiliadas ("Cummins") estão em conformidade com a lei de privacidade do consumidor da Califórnia de 2018 ("CCPA"), em relação aos residentes da Califórnia ("consumidores").</p>
            <p><strong>Visão geral da CCPA</strong><br /> A CCPA é uma lei da Califórnia que foi projetada para dar aos residentes desse Estado direitos adicionais em relação à coleta, processamento e compartilhamento de suas informações pessoais. A lei se aplica à maioria das empresas com fins lucrativos que coletam e processam informações pessoais sobre os residentes da Califórnia, mesmo que a empresa não tenha sua sede ou outros locais na Califórnia.</p>
            <p><strong>Métodos de contato</strong><br /> A Cummins colocou em prática os seguintes métodos para residentes na Califórnia exercerem seus direitos sob a CCPA. Com relação ao direito de opt-out, direito de saber/acessar e direito de solicitar exclusão, também incluímos links diretos nas seções relevantes deste aviso de privacidade de dados (abaixo) para exercer esses direitos.</p>
            <ul><li>Endereço do website: <a href="https://www.cummins.com" target="_blank" rel="noopener noreferrer">www.cummins.com</a></li>
              <li>Número Toll-Free: 1-800-286-6467</li>
              <li>Endereço de e-mail: <a href="mailto:dataprivacy@cummins.com">dataprivacy@cummins.com</a></li>
              <li>Link de suporte do website: <a href="https://www.cummins.com/customer-assistance" target="_blank" rel="noopener noreferrer">https://www.cummins.com/customer-assistance</a></li>
              <li>Endereço de correspondência: CCPA Rights, departamento jurídico da Cummins, Cummins Inc., 500 Jackson Street, Columbus, IN 47201</li>
            </ul><p>Para indivíduos com deficiência e aqueles que exigem traduções de idioma, consulte as seções relevantes deste aviso.</p>
            <p><strong>Coleta de informações pessoais</strong><br /> As informações pessoais que a Cummins coleta e processa sobre os residentes da Califórnia estão limitadas aos elementos de dados que são relevantes e proporcionais para a finalidade específica da coleção. Podem incluir:</p>
            <p>&nbsp;</p>
            <ul><li>Nome</li>
              <li>Endereço de e-mail</li>
              <li>Número de telefone</li>
              <li>Endereço</li>
              <li>Nome de usuário e senha (como para algumas áreas restritas de nossos websites)</li>
              <li>Histórico de compras</li>
              <li>Histórico de pagamento e faturamento</li>
              <li>Informações sobre garantia</li>
              <li>Histórico de serviço</li>
              <li>Interesse do produto/serviço</li>
              <li>Número de motores/geradores VIN que você comprou</li>
              <li>Identificação de seus revendedores e/ou centros de serviço</li>
              <li>Dados de desempenho de motores (ou outros sistemas chave) derivados de Telemática (apenas para usuários de telemática habilitados)</li>
              <li>Informações para responder à sua pergunta (s)</li>
              <li>Cookies do website e IP (Internet Protocol) endereço se o seu navegador está definido para permitir que os cookies.<sup style={{ color: "red", fontSize: "14px", verticalAlign: "super" }}>1</sup></li>
              <li>Informações de navegação do website coletadas por meio do Google Analytics se você visitar nossos websites.<sup style={{ color: "red", fontSize: "14px", verticalAlign: "super" }}>2</sup></li>
              <li>Informações que você pode publicar em nossas plataformas de mídia social ou em outras plataformas públicas em relação aos produtos, serviços ou negócios da Cummins</li>
            </ul><p>Consulte nossa declaração de privacidade do website <a href="#privacy"> </a> para obter informações adicionais, bem como nosso aviso de cookies que está vinculado por meio desse website.</p>
            <p><strong>Usos de informações pessoais</strong><br /> A Cummins limita seu uso de informações pessoais relativas aos residentes da Califórnia aos propósitos para os quais os dados são coletados, como atender a sua solicitação, processar seu pedido ou responder às suas perguntas. Também poderemos usar os dados para quaisquer obrigações legais, de conformidade ou regulatórias circundantes. Conforme também explicado ainda na seção de opt-out deste aviso, a Cummins não e não vai "vender" informações pessoais de residentes da Califórnia (como esse termo é definido no CCPA), ou permitir que outras organizações confiadas a seus dados (p. ex., provedores de serviço) a fazê-lo. </p>
            <p>Consulte nossa declaração de privacidade do website <a href="#privacy"> </a> para obter informações adicionais. </p>
            <p><strong>Compartilhamento de informações pessoais</strong><br /> Poderemos compartilhar suas informações pessoais com (1) nossas subsidiárias, joint ventures, coligadas e distribuidores autorizados; (2) outros provedores de serviços de terceiros nos ajudando com nossos serviços de negócios; ou (3) quando formos obrigados por lei a fazer divulgações a outros terceiros, incluindo, por exemplo, agências governamentais ou de aplicação da lei, como em relação a obrigações legais, de conformidade ou regulatórias.</p>
            <p>Em termos de divulgações de terceiros, nossos provedores de serviços ajudam em uma variedade de atividades de negócios, como nossos websites; Segurança de ti; serviços de comunicação; mídia social; aqueles que fazem parceria conosco em relação aos nossos produtos e serviços, como revendedores, distribuidores, centros de serviço e parceiros de telemática, e aqueles que nos auxiliam com outros tipos de serviços. A Cummins avalia esses provedores de serviço com antecedência para garantir que eles sejam capazes de manter um nível semelhante de proteção para os dados e também exige que eles entrem em um acordo escrito confirmando seu entendimento de que eles são expressamente proibidos de usar as informações pessoais para quaisquer finalidades não relacionadas ou da venda da informação. </p>
            <p>Quando confiamos suas informações pessoais para nossas subsidiárias e empresas afiliadas, essas empresas também se enquadram no escopo da CCPA para esses dados e são obrigados a manter um nível de proteção semelhante. Como é o caso dos provedores de serviços, nossas empresas afiliadas só podem usar essas informações para fins autorizados e são proibidas de "vender" suas informações ou compartilhá-las com outras partes para fins não relacionados, inclusive "vender" os dados. </p>
            <p>Em situações raras em que a Cummins pode ser obrigada a confiar informações pessoais limitadas de residentes da Califórnia a outros terceiros, como agências regulatórias ou governamentais ou autoridades policiais, tomamos medidas razoáveis para garantir que essas partes reconheçam a natureza confidencial e não pública da informação e irá protegê-la em conformidade. Além disso, a maioria dessas organizações é obrigada a cumprir outras leis de privacidade e segurança de dados estaduais e federais, o que significa que eles também têm a obrigação legal de manter proteções apropriadas para suas informações. </p>
            <p>Consulte nossa declaração de privacidade do website <a href="#privacy"> </a> para obter informações adicionais.</p>
            <p><strong>Uso de agentes autorizados</strong><br /> De acordo com a CCPA, a Cummins permite que os residentes da Califórnia exerçam seus direitos de CCPA por meio de um agente autorizado. Os consumidores da Califórnia devem reconhecer que, nessas situações, a Cummins requer autenticação/verificação do agente autorizado, bem como do indivíduo sobre quem os dados se relacionam, e podemos negar a solicitação se não formos capazes de obter a autenticação/verificação apropriadas em qualquer nível. Os requisitos de autenticação/verificação adotados pela Cummins variam de acordo com a direita e levam em consideração os riscos potenciais para o indivíduo de compartilhar seus dados pessoais com um indivíduo não autorizado. Consulte os direitos específicos (abaixo) e informações gerais sobre as obrigações de autenticação/verificação associadas a cada uma delas. </p>
            <p><strong>Direito de opt-out (não vender minhas informações)</strong><br /> Conforme mencionado acima, a Cummins não "venderá" as informações pessoais conforme o termo for definido no CCPA. Como garantia adicional, os residentes da Califórnia podem entrar em contato com a Cummins para solicitar que sejam adicionados a um log do not Sell, para que seus dados não sejam (inadvertidamente) sujeitos à "venda" em uma base de Go-Forward. Os residentes da Califórnia podem fazê-lo por <a href="https://www.cummins.com/sites/default/files/2020-01/Form-1-Do-Not-Sell-ccpa.pdf" target="_blank" rel="noreferrer">clicando aqui</a>, ou exercendo seu opt-out direito através de uma das outras opções listadas na seção de métodos de contato deste aviso.</p>
            <p>Indivíduos que exercem o direito de opt-out ou reter um agente autorizado para fazê-lo em seu nome, estão sujeitos a um processo de autenticação que está totalmente alinhado com os requisitos do CCPA. No caso de a Cummins não conseguir verificar o indivíduo e/ou o agente autorizado que faz a solicitação, ou tiver uma crença de boa-fé, razoável e documentada de que a solicitação para exclusão é fraudulenta (ou não fundamentada), a Cummins negará a solicitação. Nesse caso, a Cummins informará à parte solicitante que não cumprirá a solicitação e fornecerá uma explicação sobre por que acredita que a solicitação é infundada (ou fraudulenta). </p>
            <p>Se a Cummins for capaz de verificar o indivíduo sobre quem as informações pessoais se relacionam, e, se aplicável, o agente autorizado, a Cummins confirmará ao residente da Califórnia (ou agente autorizado) o mais rápido possível, e não mais que 15 dias a partir da data em que recebeu a solicitação. que o morador da Califórnia tenha optado pela venda de suas informações detidas pela Cummins e adicionado ao não vender meu registro de informações. </p>
            <p><strong>Direito de saber/acessar</strong><br /> De acordo com os requisitos da CCPA, os residentes da Califórnia podem entrar em contato com a Cummins para solicitar informações sobre as categorias, ou tipos específicos de informações pessoais que mantemos sobre eles, bem como sobre como os dados foram usados, as categorias de terceiros com quem os dados foram compartilhados e a finalidade (s) para o compartilhamento das informações. Eles podem fazê-lo por <a href="https://www.cummins.com/sites/default/files/2020-01/Form-2-Request-Access-ccpa.pdf" target="_blank" rel="noreferrer">clicando aqui</a> ou exercendo esse direito através de uma das outras opções identificadas na seção de métodos de contato deste aviso.</p>
            <p>A Cummins reconhecerá o direito de saber/acessar dentro de 10 dias após o recebimento e solicitará informações de verificação apropriadas. Os residentes da Califórnia que exercem esse direito ou usam um agente autorizado para fazê-lo estão sujeitos à verificação abrangente usando uma variedade de ferramentas de autenticação para minimizar qualquer risco para indivíduos de compartilhamento não autorizado de informações de suas pessoas. A empresa pode negar a solicitação se não for possível verificar o solicitante e/ou seu agente autorizado e, em caso de tal, informará ao agente individual/autorizado dentro de 45 dias após o recebimento de sua solicitação. Naturalmente, a Cummins também pode negar a solicitação se não tiver qualquer informação sobre o indivíduo que se enquadra no escopo da CCPA.</p>
            <p>Residentes da Califórnia devidamente verificados e/ou seus agentes autorizados têm direito a receber informações sobre (ou uma cópia de) informações pessoais coletadas pela Cummins nos últimos 12 meses antes de sua solicitação ser feita. A Cummins se esforçará para atender a esses pedidos dentro de 45 dias, mas pode exigir um período adicional de tempo (até um total de 90 dias), se necessário. Se assim for, informará o solicitante sobre o atraso e as razões, portanto, dentro do período de 45 dias. </p>
            <p>Os residentes da Califórnia estão limitados a dois desses pedidos para saber/acessar seus dados em qualquer período de 12 meses (seja diretamente ou por meio de um agente autorizado). <br />
              &nbsp;</p>
            <p><strong>Direito de solicitar exclusão</strong><br /> Com algumas exceções, conforme descrito abaixo, os residentes da Califórnia têm o direito de solicitar a exclusão de suas informações pessoais e podem fazer a solicitação diretamente ou por meio de um agente autorizado. Eles podem fazê-lo por <a href="https://www.cummins.com/sites/default/files/2020-01/Form-3-Request-Deletion-ccpa.pdf" target="_blank" rel="noreferrer">clicando aqui</a> ou exercendo esse direito através de uma das outras opções identificadas na seção de métodos de contato deste aviso.</p>
            <p>A Cummins não é obrigada a excluir as informações pessoais se ela deve ser mantida para um dos seguintes propósitos:</p>
            <ul><li>Complete uma transação para a qual as informações pessoais foram coletadas. </li>
              <li>Cumpra os termos de uma garantia escrita ou recall de produto realizada de acordo com a lei federal.</li>
              <li>Fornecer um bem ou serviço solicitado pelo consumidor (residente na Califórnia) ou razoavelmente antecipado dentro do contexto da relação comercial contínua de uma empresa com o consumidor.</li>
              <li>Caso contrário, execute um contrato entre a empresa e o consumidor.</li>
              <li>Detecte incidentes de segurança.</li>
              <li>Proteja contra atividades maliciosas, enganosas, fraudulentas ou ilegais ou processe os responsáveis por essa atividade.</li>
              <li>Debug para identificar e reparar erros que prejudicam a funcionalidade pretendida existente.</li>
              <li>Exercer a liberdade de expressão, garantir o direito de outro consumidor de exercer seu direito de liberdade de expressão ou exercer outro direito previsto na lei.</li>
              <li>Participe de pesquisas científicas, históricas ou estatísticas de interesse público ou revisadas por pares no interesse público que adere a todas as outras leis de ética e privacidade aplicáveis, quando a exclusão de empresas da informação é susceptível de tornar impossível ou seriamente prejudicar a realização de tal pesquisa, se o consumidor tiver fornecido consentimento informado.</li>
              <li>Possibilitar o uso exclusivo dos dados internos que estejam razoavelmente alinhados às expectativas do consumidor com base na relação do consumidor com o negócio.</li>
              <li>Cumprir uma obrigação legal.</li>
              <li>De outra forma, use as informações pessoais do consumidor, internamente, de forma legal que seja compatível com o contexto em que o consumidor forneceu as informações.</li>
            </ul><p>Indivíduos que exerçam o direito de solicitar exclusão ou reter um agente autorizado para fazê-lo em seu nome, serão sujeitos a uma verificação detalhada de acordo com os requisitos do CCPA.</p>
            <p>A Cummins reconhecerá uma solicitação de exclusão dentro de 10 dias após o recebimento e se esforçará para atender à solicitação em até 45 dias. Se se tornar evidente que a Cummins não consegue excluir todos os dados do morador da Califórnia dentro do período de 45 dias, a empresa informará o indivíduo e/ou o agente autorizado dentro desse período e poderá demorar mais 45 dias para atender à solicitação (ou seja, um total de 90 dias). </p>
            <p>Em qualquer caso, quando a Cummins processa uma solicitação de exclusão de um morador da Califórnia (que foi devidamente verificado), excluirá os dados de seus sistemas de forma segura e irreversível, de modo que os dados não possam ser restaurados. Ao mesmo tempo, de acordo com a CCPA, a Cummins pode abster-se de excluir cópias de informações armazenadas em sistemas de backup se não for possível fazê-lo no momento em que exclui as informações pessoais remanescentes no escopo da solicitação. Nessas situações, a Cummins marcará esses dados para eliminação futura quando a exclusão pode ocorrer. Além disso, a Cummins não usará esses dados para nenhuma finalidade não relacionada depois que estiver marcado para eliminação. </p>
            <p>Na medida em que a Cummins confiou qualquer uma das informações pessoais do morador da Califórnia a um provedor de serviços, ele também entrará em contato com esse provedor de serviços para processar a solicitação de exclusão e confirmará ao residente da Califórnia (ou seu agente autorizado, se aplicável) que o tenha feito.</p>
            <p><strong>Direitos de menores</strong><br /> A Cummins mantém os direitos dos residentes da Califórnia que são menores, inclusive aqueles que se aplicam a menores que tenham menos de 13 anos e aqueles que se aplicam a menores entre 13 e 16 anos de idade. A Cummins também criou práticas para manter as obrigações circundantes aos pais e guardiões legais desses menores.</p>
            <p>Os pais e tutores legais que buscam exercer os direitos do CCPA de seus filhos também serão submetidos à autenticação para evitar a liberação de qualquer informação relativa a um menor para um indivíduo não autorizado.</p>
            <p><strong>Direito a nenhuma discriminação</strong><br /> De acordo com a CCPA, a Cummins não discrimina indivíduos. inclusive aqueles que optam por exercer seus direitos de CCPA. Consulte a declaração de privacidade do website da Cummins na www.CumminsDiagnostics.com para obter informações adicionais sobre as práticas de antidiscriminação da empresa, inclusive em relação às leis de direitos civis federais e estaduais.</p>
            <p><strong>Direitos dos indivíduos com deficiência</strong><br /> A Cummins oferece várias maneiras de se comunicar conosco para fornecer assistência a indivíduos com deficiência e permitir que cada indivíduo se comunique de forma eficaz. Para saber mais, acesse <a href="https://www.cummins.com/pt/cummins-care" target="_blank" rel="noreferrer"> www.cummins.com/cummins-care</a>.</p>
            <p><strong>Perguntas ou preocupações</strong><br /> Os residentes da Califórnia que tiverem dúvidas ou que acreditem que a Cummins tenha gerenciado incorretamente suas informações pessoais ou violado seus direitos podem entrar em contato conosco usando os métodos de contato listados neste aviso. </p>
            <p><strong>Informações adicionais</strong><br /> Para obter mais informações sobre as práticas de CCPA da Cummins, acesse nosso website em <a href="https://www.cummins.com" target="_blank" rel="noreferrer">www.cummins.com</a> ou entre em contato conosco por meio de uma das opções fornecidas na seção "métodos de contato" deste aviso. </p>
            <p><strong>Atualizações para este aviso</strong><br /> A Cummins revisará seus avisos e práticas da CCPA em pelo menos uma base anual, e também poderá fazer atualizações para este aviso em outros intervalos, como em relação a novas informações emitidas pelos reguladores da Califórnia ou em conexão com mudanças em seus negócios Atividades. Encorajamos você a revisar este aviso periodicamente para ter certeza de que você está ciente dessas mudanças. Qualquer alteração servirá para melhorar ou fortalecer as proteções de privacidade e segurança que estão no lugar para informações pessoais dos residentes da Califórnia, e não diminuí-las.</p>
            <br /><br /><br />
            <hr style={{ width: "100%", color: "red", borderTop: "solid 1px red" }} /><p id="Reference1"><sup style={{ color: "red", fontSize: "14px", verticalAlign: "super" }}>1</sup> cookies são pequenos pedaços de dados enviados de um website e armazenados no navegador da Web de um usuário.Quando o usuário navegar pelo mesmo website no futuro, os cookies notificarão o website sobre as atividades anteriores do usuário.Cookies não ameaçam seu computador de nenhuma forma, e alguns são automaticamente excluídos depois de um certo período de tempo, como seis meses.Embora os cookies identifiquem o endereço IP do visitante do website, eles não identificam o próprio visitante.Consulte os cookies do website da Cummins, bem como informações adicionais em nossa declaração de privacidade do website, que são acessíveis em <a href="https://www.cummins.com" target="_blank" rel="noreferrer">www.cummins.com</a>.</p>
            <br /><br />
            <p id="Reference2"><sup style={{ color: "red", fontSize: "14px", verticalAlign: "super" }}>2</sup> o Google Analytics é um serviço de análise da Web oferecido pelo Google que rastreia e relata o tráfego do website para os proprietários do website.É usado por mais de um terço de todos os websites, de modo que os operadores do website possam avaliar o uso do site, fazer melhorias e oferecer conteúdo que seja de interesse para os visitantes.A ferramenta não identifica visitantes específicos, mas captura endereços IP se o visitante define seu navegador para permitir cookies.</p>
          </CollapseItem>
        </CollapseContainer>
      </ContainerDiv>
    </CollapseDiv>
  );
};

export default Collapse;