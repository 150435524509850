import React, { FC, useContext } from 'react';

import HeaderNews from '../../layout/HeaderNews';
import Footer from '../../layout/Footer';

import { MainContext } from '../../providers/MainProvider';

import { IPrivacyLegal } from './interfaces';

import { ContainerDiv } from '../../layout/Container/styles';
import { NoticeSectionDiv } from '../Notice/styles';
import { H2 } from '../../components/Typography/styles';
import { PrivacyLegalContainer, PrivacyLegalSection } from './styles';
import Collapse from '../../components/Collapse';

// privacy legal
const PrivacyLegal: FC<IPrivacyLegal> = () => {
  const { footer }: any = useContext(MainContext);

  // render
  return (
    <PrivacyLegalSection>
      <HeaderNews type={2} />

      <NoticeSectionDiv>
        <ContainerDiv>
          <PrivacyLegalContainer>
            <H2>{`PRIVACIDADE E ASSUNTOS\nJURÍDICOS`}</H2>
            <Collapse />
          </PrivacyLegalContainer>
        </ContainerDiv>
      </NoticeSectionDiv>

      {footer &&
        <Footer {...footer} />}
    </PrivacyLegalSection>
  );
};

export default PrivacyLegal;