import styled from "styled-components";

const InfoSection = styled.section`
  margin: 0 0 20px;
  width: 100%;

  > div {
    h3 {
      width: calc(100% - 490px);
    }

    p {
      color: ${({ theme }) => theme.black_800};
      margin: 0;
      white-space: pre-line;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    h1 {
      display: none;
    }

    > div {
      h3 {
        width: 100%;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    > div {
      padding: 0 20px;
    }
  }
`;

export {
  InfoSection
}