import React, { FC } from 'react';

import DOMPurify from 'dompurify';
import { Link as LinkScroll } from "react-scroll";

import { H2, H4, P } from '../../Typography/styles';
import { WebdoorContainerDiv } from './styles';

import { IWebdoorContainer } from './interfaces';

// webdoor container
const WebdoorContainer: FC<IWebdoorContainer> = ({
  description,
  link,
  subtitle,
  title,
}) => {
  const sanitizedTitle = () => ({
    __html: DOMPurify.sanitize(title)
  });

  return (
    <WebdoorContainerDiv>
      <H4 data-aos="fade-up" data-aos-delay={200}>{subtitle}</H4>
      <H2 data-aos="fade-up" data-aos-delay={300} dangerouslySetInnerHTML={sanitizedTitle()}></H2>
      <P data-aos="fade-up" data-aos-delay={400}>{description}</P>

      <LinkScroll
        className="link"
        data-aos="fade-in"
        data-aos-delay={700}
        to={link?.to}
        spy={true}
        smooth={true}
        offset={40}
        duration={500}>
          <P>{link?.text}</P>
      </LinkScroll>
    </WebdoorContainerDiv>
  );
};

export default WebdoorContainer;