import styled from "styled-components";

const SubTitleDiv = styled.div`
  float: left;
  padding: 50px 0;
  text-align: center;
  width: 100%;

  > div {
    text-align: center;
  }

  h3 {
    float: none;
    margin: 0 0 20px;
    text-align: center;
    text-transform: uppercase;
    width: calc(100% - 600px);
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    h3 {
      width: calc(100% - 200px);
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    h3 {
      width: calc(100% - 300px);
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 40px 50px;

    h3 {
      font-size: 28px;
      width: 100%;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 40px 25px;
  }
`;

const SubTitleIconDiv = styled.div`
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 90px;

  span {
    background-color: ${({ theme }) => theme.red_900};
    float: left;
    height: 10px;
    margin: 0 4px;
    width: 10px;
  }
`;

export {
  SubTitleDiv,
  SubTitleIconDiv
}