import styled from "styled-components";

const SelectTitleButton = styled.button`
  background-color: ${({ theme }) => theme.white_900};
  border: 1px solid ${({ theme }) => theme.black_900};
  border-radius: 5px;
  height: 50px;
  float: left;
  padding: 0;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  .select-title {
    &--title {
      align-items: center;
      display: inline-flex;
      justify-content: flex-start;
      flex-flow: row nowrap;
      float: left;
      height: 100%;
      margin: 0;
      padding: 0 20px;
      width: 100%;
      user-select: none;
      vertical-align: top;

      .icon,
      .text {
        float: left;
        position: relative;
      }

      .icon {
        color: ${({ theme }) => theme.red_900};
        font-size: 12px;
        padding: 0;
        right: 20px;
        position: absolute;
        top: calc(50% + 1px);
        transform: translate(0, -50%) rotate(0deg);
      }

      .text {
        color: ${({ theme }) => theme.black_800};
        float: left;
        font-family: Helvetica;
        font-weight: 400;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &[data-active="true"] {
    .select-title {
      &--title {
        .icon {
          transform: translate(0, calc(-50% - 1px)) rotate(180deg);
        }
      }
    }
  }
`;

export {
  SelectTitleButton,
}