import React, { FC } from 'react';

import Subtitle from '../Typography/Subtitle';
import Title from '../Typography/Title';

import { ContainerDiv, ContainerTextDiv } from '../../layout/Container/styles';
import { P } from '../Typography/styles';
import { InfoSection } from './styles';

import { IInfo } from './interfaces';

// info
const Info: FC<IInfo> = ({
  description,
  subtitle,
  title,
}) => {
  // render
  return (
    <InfoSection>
      <ContainerDiv>
        <Title
          title={title} 
          type={2} />

        <Subtitle
          subtitle={subtitle} />

        <ContainerTextDiv>
          <P>{description}</P>
        </ContainerTextDiv>
      </ContainerDiv>
    </InfoSection>
  );
};

export default Info;