import styled from "styled-components";

const WebdoorSection = styled.section`
  display: flex;
  margin: 0 0 40px;
  width: 100%;

  .container {
    display: inline-block;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 0;
    padding: 280px 0 0;
  }
`;

const WebdoorImageDiv = styled.div`
  bottom: -93px;
  left: -70px;
  position: absolute;
  width: 675px;

  img {
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    left: -150px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    bottom: initial;
    left: 50%;
    padding: 0 20px;
    top: 0;
    transform: translate(-50%, -250px) !important;
    width: 480px;
  }
`;

const WebdoorBgDiv = styled.div`
  background-color: ${({ theme }) => theme.black_800};
  right: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: calc(50vw + 220px);

  img {
    height: 100%;
    mix-blend-mode: color-burn;
    object-fit: cover;
    width: 100%;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    width: calc(100vw - 385px);
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    bottom: 0;
    height: calc(100% - 285px);
    top: initial;
    width: 100%;
  }
`;

export {
  WebdoorSection,
  WebdoorImageDiv,
  WebdoorBgDiv
}