import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import Slider from "react-slick";

import NewsControl from './NewsControl';
import NewsItem from './NewsItem';
import Subtitle from '../Typography/Subtitle';
import Title from '../Typography/Title';

import { ContainerDiv } from '../../layout/Container/styles';
import { NewsSection } from './styles';

import { INews } from './interfaces';

import { settings } from './settings';

const { REACT_APP_BASE_API, REACT_APP_PAGINATION_NEWS_HOME } = process.env;

// news
const News: FC<INews> = ({
  subtitle,
  title,
}) => {
  const [data, setData] = useState<any>({});
  const query: string = '?type=imprensa.Noticia&fields=date,image,intro,title&order=-first_published_at';

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${REACT_APP_BASE_API}/api/v2/pages/${query}&offset=0&limit=${REACT_APP_PAGINATION_NEWS_HOME}`
        ).then(({ data }: any) => data);

        setData(result);
      } catch (error) {
        console.error(`Error load news: ${error}`);
      }
    };

    fetchData();
  }, []);

  // render
  return (
    <NewsSection>
      <ContainerDiv>
        <Title
          title={title}
          type={2} />

        <Subtitle
          subtitle={subtitle} />

        {Array.isArray(data?.items) && <Slider
          prevArrow={<NewsControl type="prev" />}
          nextArrow={<NewsControl type="next" />}
          {...settings}>
            {data?.items.map((item: any, index: number) => 
                <NewsItem {...item} key={index} />)}
          </Slider>}
      </ContainerDiv>
    </NewsSection>
  );
};

export default News;