import styled from "styled-components";

const NewsItemDiv = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;

  &:before {
    background-color: ${({ theme }) => theme.black_900};
    content: '';
    height: calc(100% - 20px);
    left: 10px;
    position: absolute;
    top: 10px;
    width: calc(100% - 20px);
  }
`;

const NewsItemBackgroundDiv = styled.div`
  height: 190px;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const NewsItemInfoDiv = styled.div`
  float: left;
  height: calc(100% - 190px);
  padding: 25px 30px 80px;
  width: 100%;

  .date,
  .description {
    margin: 0;
    width: 100%;
  }

  .date {
    color: ${({ theme }) => theme.red_900};
  }

  .description {
    color: ${({ theme }) => theme.white_900};
    font-weight: 700;
    margin: 5px 0;
    text-transform: uppercase;
  }

  .min {
    bottom: 25px;
    left: 30px;
    position: absolute;
  }
`;

export {
  NewsItemDiv,
  NewsItemInfoDiv,
  NewsItemBackgroundDiv
}