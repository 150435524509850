import styled from "styled-components";

const HeaderSection = styled.header`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;

  .link {
    background-color: ${({ theme }) => theme.red_900};
    float: left;
    height: 135px;
    width: 105px;

    .icon {
      bottom: 20px;
      color: ${({ theme }) => theme.white_900};
      font-size: 70px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 0 50px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    padding: 0 20px;

    .link {
      height: 75px;
      width: 60px;

      .icon {
        bottom: 10px;
        font-size: 38px;
      }
    }
  }
`;

export {
  HeaderSection
}