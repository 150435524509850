import styled from "styled-components";

const NewsControlButton = styled.button`
  background-color: ${({ theme }) => theme.white_900};
  border: 2px solid ${({ theme }) => theme.red_900};
  bottom: -80px;
  cursor: pointer;
  height: 40px;
  outline: none;
  padding: 0;
  position: absolute;
  transform: translate(-50%, 0);
  width: 65px;

  .icon {
    color: ${({ theme }) => theme.red_900};
    font-size: 9px;
    left: 50%;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  &[data-type="prev"] {
    left: calc(50% - 31px);

    .icon {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &[data-type="next"] {
    left: calc(50% + 31px);
  }
`;

export {
  NewsControlButton
}