import axios from 'axios';
import React, { createContext, FC, useEffect, useState } from 'react';

import { IMainContext, IMainProvider } from './interfaces';

// main context
const MainContext = createContext({} as IMainContext);

// main provider
const MainProvider: FC<IMainProvider> = ({
  children,
}) => {
  const [ data, setData ] = useState<any | null>({});

  // use effect
  useEffect(() => {
    const load = async () => {
      await axios.get(`/data/main.json`)
        .then(res => setData(res.data))
        .catch(err => console.log(err))
    };

    load();
  }, []);

  // render
  return (
    <MainContext.Provider
      value={{
        ...data
      }}>
      {children}
    </MainContext.Provider>
  );
};

export { MainProvider, MainContext };
export default MainProvider;