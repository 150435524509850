import React, { FC } from 'react';

import { H1 } from '../styles';
import { TitleDiv } from './styles';

import { ITitle } from './interfaces';

// title
const Title: FC<ITitle> = ({
  title,
  type = 1
}) => {
  // render
  return (
    <TitleDiv data-type={type}>
      <H1 data-aos={type === 1 ? 'fade-left' : 'fade-right'} data-aos-delay={400}>{title}</H1>
    </TitleDiv>
  );
};

export default Title;