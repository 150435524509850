import styled from "styled-components";

const ContactSection = styled.section`
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAAoCAYAAAChDJfXAAADt0lEQVRogb2ZgVXbMBCG/8sCtiYg3QA2oBuwAekEDROQbEAnIJ2gdAKyAWGCZgMpDGD1XZD8hCw7siz5f4+Hkf2482fp7nQiGCml3gBcYyZprW+EEAdrTUq5IaLHGe1v67re8PXCGfw7lwMAji4Ao/2M9lk7e9FCAPDEzs1hXWv9wx8TQuy11r9msr8VQrTv2kIQQiit9ffSIBgAv3DonhBirbX+Xdg+A9i4Y+5MYCeOJUEYALuhZ4QQq1IgQgDgQ0BBEDEAHB+yg+gDwCKOygBe/EAlpVwS0SuAZQYHOgCklDWA1WKxuGqa5j0ESEq5I6L7DPY7AKSUtwD4B6SU0ucLoqeqqh68ByeD6AFwR0TPAGpn+DwD3YCFDCBCAE6n06PWuh1zU+T6dDo9uw9PXRohAB8fH/dE9McDwDoDZ/CeD8lLIwYA/JigtV7lAtEHoGmaobiQDUQsAIQCYw4QiQCsJoMYAwAhCJgIYiIAq2QQYwGgDwISQWQCYDUaRAoADEHABRCeI3sT2XMBsBoCsXU+Ble7DykA4KbIwYeIdlVVdep9mHzPJbc/ngGAq2D6NPaXofFYAIiFgAsgfGUGYNULwtcYALi0HFyFlkZIhQCgb2n4GgsAYyAgAgRXgoUAWA2CSAGAsRBgQEgp1/64KbEvzpQMCtqRUq5SACAFAj7jQ6gNtg6UwqV0azZArk8/U20lQeCXNbtA14mrgi8dkr8kkvujqRCOflrUWr+nOpGobD3KJAimUPHFPcpOvVBCnK79/seU/mRKYNyGGiBOj7I0iGPTNJ2PIIR46fk4FzU2Rfa2qIwjh8IgBgsm9i0FxJhiaRCA40gpEFEVYwqIKAg+AM4MXJgopSSX3UqpLwVMARAdAFwX8KmZsf/Gfzv2R4G4uHcIAFia9pifkpRx1D1auzY9yin1QwdAX2VIRJuqqtqXjz3au7SVDgF47cnJtSlp23sZZkQ0AOPvhu879qNmxFBTpQ/A0AYmJ4hRABy/R4Poa6+lALDKASIJgOP/KBChRusUAFZTQEwC4LxHNAi/5Z4DgFUKiCwArGJBuIcvOQFYDYHw8/0+JwCrGBB8Fslb4IN7XJ7zHNIolD6X5iyQf+/94/ocAFwF0ifbveNr0vqzTCCi9mZmAFYdEH3KDcDKB2Hf/UtMKAgAoaURUikACCwNqy8QTCVYAoDVIIiSAKz4//vtwXY5KKVWM/UIWYe6rm/cATML/81kn2F8q+v6HITbmTClR5eg68BsaDdAM+kcFAHgP3/DKFtF7EKNAAAAAElFTkSuQmCC');
  background-repeat: repeat;
  margin: 60px 0 0;
  padding: 120px 0 0;
  width: 100%;

  .container {
    > p {
      color: ${({ theme }) => theme.black_800};
      margin: 0 0 40px;
    }
  }

  > div {
    > div {
      &:nth-child(2) {
        top: calc(50% - 100px);
      }

      &:first-child {
        padding-bottom: 30px;
      }
    }
  }

  &:before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAAoCAYAAAChDJfXAAADXUlEQVRoge2Z3XEbIRDHF81k8nhHBVY6UDpwOnAHUSqIUoHlDpwK7A7iDuwOLFcQdQDnt7wcmZVZzYqDO+BAT/rPaCTdB7v8+FoWuOiiiy666KKBhFJqCwBPUsod3TTGVCMlhDh8K6VaAFgvFourvu/fpJSP/LlUH6jcWCmlrgHg+vCu1trYQu6bpvk1x5FYR5VSN0KIBwBo2e29MeablHKfYz8FQtd1t8aYLf1fMIObrusecguOdfL9/f27EOKPAwC1FEI8K6WWNeyTXADAIcAHiHVNEAig7/vHkUeqgvABABcCVACB7+InAgDJC2IujBAA8EGAgiD4EIgEQBqAyPUBJgBACAIEQMSKt1wGAFIREFMAYAwCeECQA1rrz6F3uJMzAJBmgYgBAFMQIABCSvmPO0ItXxgAKQgCP1rrT76XYgEAjxMmHxTisWmaHzHPFgTANYgjSBhP8AZIAQAxPYEZipojKgGAUI9wlQoAUiBABAiMBCsBII2CyAEAqRDAglBKbdzr6JgNhWvLa0cptc4BkAUBPuaHW8/ljScUrqVruwHiPv3MtZUFAStrd4HciauKlfbJHRKr3IJyIeyllJpfMMa85TqRqZ3z2ktuQVkQjDF3nsv3GEflOpIiXK55/sP69Du3vJyJ8c5NgKCwZ+A6fgYQ+77vB40gpXwKNM6kUpdIBBCcgbF1KoMIBkzW/jYHREqwNAqAOVILxCgAZj8ZRBQEFwCuDBiYaK0Vht1a65MApgKIAQCMC7TWr2i/67pX/M/sJ4GY3Dt4ACxtesxdkrR1dMeeXWGENzN+GAAIRYZCiG3TNMfKYxI5ENOcaGor7QPwHFiTWxvSHu8V6BHRAKy/W7zP7Ef1iLGkSgjA2AamJIgkAMzvZBCh9FoOAFIJEFkAmP9JIHyJ1jkASHNAzALA6hENwk25lwBAygFRBAApFgQ/fCkJgDQGwl3vX0oCIMWAwLNI3ALvpJTHDUghAFy+5XNpzwLx+4Xbh0IAuDzLJ9q9wd+CzvvYOWFpAKQBiJBKAyC5IKjuJ3NCRQDgGxo+1QIAnqFBOoFgI8EaAEijIGoCIGH5bnrwOBy01usz5QhRu7Ztv/ILthf+PZN9hPGlbdvDJHzsCXNydBlaeXrDcQN0Jh0mRQCA/6VXTOhaEvPOAAAAAElFTkSuQmCC');
    background-repeat: repeat;
    content: '';
    left: 0;
    height: 50px;
    position: absolute;
    top: -39px;
    width: 100%;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding-top: 160px;

    > div {
      > div {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    > div {
      > div {
        &:nth-child(3) {
          padding: 0 20px;
        }
      }
    }
  }
`;

export {
  ContactSection
}