import React, { FC } from 'react';

import { ButtonRouteLink } from '../../Form/Button/styles';
import { P, PMin } from '../../Typography/styles';

import { INewsItem } from './interfaces';

import { NewsItemDiv, NewsItemInfoDiv, NewsItemBackgroundDiv } from './styles';
import useForm from '../../../hooks/useForm';

const { PUBLIC_URL, REACT_APP_BASE_API } = process.env;

// news item
const NewsItem: FC<INewsItem> = ({
  date,
  id,
  meta,
  image,
  title
}) => {
  const { getDate } = useForm();

  // render
  return (
    <NewsItemDiv>
      <NewsItemBackgroundDiv>
        <img
          src={`${REACT_APP_BASE_API}${image?.meta.download_url}` || `${PUBLIC_URL}/images/news-item.jpg`}
          alt={image?.meta.title || 'news'} />
      </NewsItemBackgroundDiv>

      <NewsItemInfoDiv>
        <PMin className="date">{getDate(date)}</PMin>
        <P className="description">{title}</P>
        <ButtonRouteLink
          className="min"
          to={`/noticias/${meta?.slug}`}>Ver mais</ButtonRouteLink>
      </NewsItemInfoDiv>
    </NewsItemDiv>
  );
};

export default NewsItem;