import React, { FC } from 'react';

import { INewsControl } from './interfaces';
import { NewsControlButton } from './styles';

// news control
const NewsControl: FC<INewsControl> = ({
  onClick = false,
  type
}) => {
  return (
    <NewsControlButton
      data-type={type}
      onClick={onClick}>
      <span className="icon icon-arrow-down"></span>
    </NewsControlButton>
  );
};

export default NewsControl;