import styled from "styled-components";

const CollapseItemHeaderDiv = styled.div`
  cursor: pointer;
  padding: 20px 0;
  width: 100%;

  .title {
    color: ${({ theme }) => theme.black_900};
    font-family: Helvetica;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    margin: 0;
    padding: 0 90px 0 0;
    line-height: 1em;
    letter-spacing: -0.03em;
  }

  .btn-collapse {
    background: none;
    border: none;
    float: right;
    outline: none;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);

    .icon {
      color: ${({ theme }) => theme.red_900};
      font-size: 10px;
      margin: 0;
      float: left;
      padding: 0;
    }
  }

  &[data-collapse="true"] {
    .btn-collapse {
      .icon {
        transform: rotate(180deg);
      }
    }
  }
`;

export {
  CollapseItemHeaderDiv
}